import gql from 'graphql-tag'

export const CUSTOMERS = gql`
  query customers($searchTerm: String, $page: Int!, $pageSize: Int!) {
    results: marketplaceCustomersList(
      searchTerm: $searchTerm
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      totalEntries
      totalPages
      entries {
        user {
          id
          name
          email
        }
        totalSpent
        totalOrders
      }
    }
  }
`
