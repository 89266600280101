import styled, { css } from 'styled-components'
import * as UI from 'cimple-web-common'

export const HeadingM = styled(UI.HeadingM)`
  margin-bottom: 0.5rem;
`

export const Text = styled(UI.Text)`
  display: inline-flex;

  ${p =>
    p.underlined &&
    css`
      text-decoration: underline;
    `}
`

export const ModalSubmit = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  margin-top: 1.5rem;
`

export const Flex = styled.div`
  display: flex;
  justify-content: row;
`

export const ErrorHolder = styled.div`
  width: 100%;
  max-height: 20.4rem;
  overflow: scroll;

  margin-bottom: 2rem;
`

export const Error = styled.div`
  margin-bottom: 1rem;
`

export const ErrorNumber = styled(UI.Text)`
  margin-right: 0.2rem;
`

export const ErrorDescription = styled(UI.Text)`
  margin-bottom: 0.2rem;
`

export const Modal = styled(UI.Modal)`
  max-width: 32rem;
`

export const Select = styled(UI.Select)`
  margin-top: 2rem;
`

export const FlexRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`

export const GoodList = styled(UI.GoodList)`
  margin-bottom: 1rem;

  &:first-of-type {
    margin-top: 1rem;
  }
`
