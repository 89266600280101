// @ts-nocheck
import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Formik, Form, FieldArray, Field } from 'formik'

import { Icons } from 'cimple-web-common'

import Stepper from './Stepper'

import * as S from '../styled'
import * as I from 'modules/Catalogue/GoodCreate/types'
import { IconLink } from 'components/Shared/Links'

export const Characteristics = ({
  field,
  t,
  ...props
}: I.FieldCharacteristics) => {
  return (
    <S.InputSmall
      type="text"
      label={t({ id: 'catalogueMarketplace.goodCharacteristics' })}
      name={t({ id: 'catalogueMarketplace.goodSpecification' })}
      placeholder={t({ id: 'catalogueMarketplace.goodCharacteristics' })}
      {...field}
      {...props}
    />
  )
}

export const Values = ({ field, ...props }: I.FieldValues) => {
  return (
    <S.InputSmall
      type="text"
      label="Values"
      name="specification"
      placeholder="Values"
      {...field}
      {...props}
    />
  )
}

type StepProductCharacteristicsProps = {
  intl: IntlShape
  stepNumber: number
  setStepNumber: (currentStep: number) => void
  submitStep: Function
}

type KeyValue = { key: string; value: string }

type FormikValues = {
  specifications: KeyValue[]
}

type FormikBag = {
  values: FormikValues
  isValid: boolean
}

const StepProductCharacteristics: React.FC<StepProductCharacteristicsProps> = ({
  intl: { formatMessage: t },
  stepNumber,
  setStepNumber,
  submitStep
}) => {
  return (
    <div>
      <S.Title>{t({ id: 'catalogueMarketplace.goodCharacteristics' })}</S.Title>
      <Formik
        initialValues={{
          specifications: [{ key: '', value: '' }]
        }}
        onSubmit={(values: FormikValues) => submitStep(values)}
        render={({ values, isValid }: FormikBag) => {
          return (
            <Form>
              <FieldArray
                name="specifications"
                render={arrayHelpers => {
                  return (
                    <div>
                      {values.specifications.map(
                        (spec: KeyValue, i: number) => {
                          return (
                            <S.KeyValue key={`${spec}_${i}`}>
                              <Field
                                component={Characteristics}
                                name={`specifications.${i}.key`}
                                index={i}
                                t={t}
                              />
                              <S.Line />
                              <Field
                                component={Values}
                                name={`specifications.${i}.value`}
                                index={i}
                                t={t}
                              />
                            </S.KeyValue>
                          )
                        }
                      )}

                      <IconLink
                        handleClick={() => arrayHelpers.insert(0, '')}
                        icon={<Icons.PlusBig />}
                      >
                        {t({ id: 'catalogueMarketplace.addCharacteristics' })}
                      </IconLink>
                    </div>
                  )
                }}
              />
              <Stepper
                isValid={isValid}
                stepNumber={stepNumber}
                setStepNumber={setStepNumber}
              />
            </Form>
          )
        }}
      />
    </div>
  )
}

export default injectIntl(StepProductCharacteristics)
