export const safeSetPage = (
  newPage: number,
  setPage: (page: number) => void,
  totalPages: number
): number | void => {
  if (newPage < 1) {
    return setPage(1)
  }

  if (newPage > totalPages) {
    return 0
  }

  return setPage(newPage)
}
