import React from 'react'
import { HeadingS } from 'cimple-web-common'
import * as S from './styled'

import noResults from 'assets/cimple-noResults@3x.png'
import noOrders from 'assets/cimple-noOrders@3x.png'
import noMatches from 'assets/cimple-noMatches@3x.png'

type ErrorType = 'ERROR_MATCHES' | 'ERROR_ORDERS' | 'ERROR_RESULTS'

type ErrorProps = {
  errorType?: ErrorType
}

const Error: React.FC<ErrorProps> = ({ errorType }) => {
  // When there are no entries, matches, etc...
  const errorTypes = {
    ERROR_MATCHES: <ErrorMatches />,
    ERROR_ORDERS: <ErrorOrders />,
    ERROR_RESULTS: <ErrorResults />
  }

  if (errorType) {
    return errorTypes[errorType]
  }

  // A network or backend error
  return <ErrorDefault />
}

const ErrorMatches = () => {
  return (
    <S.Error>
      <S.ErrorImage src={noMatches} alt="An error has occured." />
      <HeadingS>No matches found.</HeadingS>
    </S.Error>
  )
}

const ErrorOrders = () => {
  return (
    <S.Error>
      <S.ErrorImage src={noOrders} alt="An error has occured." />
      <HeadingS>No orders found.</HeadingS>
    </S.Error>
  )
}

const ErrorResults = () => {
  return (
    <S.Error>
      <S.ErrorImage src={noResults} alt="An error has occured." />
      <HeadingS>No results found.</HeadingS>
    </S.Error>
  )
}

const ErrorDefault = () => {
  return (
    <S.Error>
      <S.ErrorImage src={noOrders} alt="An error has occured." />
      <HeadingS>There was a problem.</HeadingS>
    </S.Error>
  )
}

export default Error
