import gql from 'graphql-tag'

export const GET_ALL_SOURCES = gql`
  query allSources($searchTerm: String, $page: Int!, $pageSize: Int!) {
    signpostSourcesList(
      searchTerm: $searchTerm
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      totalPages
      totalEntries
      entries {
        id
        name
        email
        website
        image {
          small
          medium
        }
      }
    }
  }
`

export const CREATE_SOURCE = gql`
  mutation createSource($input: SignpostSourceCreateInput!, $image: Upload!) {
    signpostSourceCreate(input: $input, image: $image) {
      id
    }
  }
`

export const GET_SOURCE = gql`
  query getSource($id: ID!) {
    results: signpostSource(id: $id) {
      description
      email
      id
      image {
        small
      }
      name
      website
    }
  }
`

export const EDIT_SOURCE = gql`
  mutation editSource($input: SignpostSourceEditInput!, $image: Upload) {
    signpostSourceEdit(input: $input, image: $image) {
      id
    }
  }
`
