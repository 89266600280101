import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import CatalogueGoodCreate from './CatalogueGoodCreate'

import { GOOD_CREATE_MARKETPLACE } from 'modules/Catalogue/GoodCreate/queries'
import { ROUTES } from 'constants/routes'

export type KeyValue = {
  key: string
  value: string
}

type DataMarketplace = {
  goodName: string
  goodSku: string
  goodPrice: string
  goodVAT: string
  goodCategory: string
  goodSupplier: string
  goodUnitType: string
  goodDescription: string
  goodExternalId: string
  specifications: KeyValue
}

const CatalogueMarketplaceGoodCreate = () => {
  const history = useHistory()
  const [marketplaceGoodCreate] = useMutation(GOOD_CREATE_MARKETPLACE)

  const submitMarketplaceGoodCreate = (
    removeImage: Function,
    createObjectWithImageKeys: () => { number: File },
    goodData: DataMarketplace
  ) => {
    const {
      goodName,
      goodSku,
      goodPrice,
      goodVAT,
      goodCategory,
      goodSupplier,
      goodUnitType,
      goodDescription,
      goodExternalId,
      specifications
    } = goodData

    const info = {
      category: goodCategory,
      description: goodDescription,
      name: goodName,
      price: goodPrice,
      sku: goodSku,
      specifications: specifications,
      supplierId: goodSupplier,
      vat: goodVAT,
      unitType: goodUnitType,
      externalId: goodExternalId
    }

    const variables = {
      input: removeImage(info),
      ...createObjectWithImageKeys
    }

    marketplaceGoodCreate({
      variables: variables
    }).then(({ data }) => {
      if (data && data.result) {
        history.push({ pathname: ROUTES.catalogueMarketplace })
      }
    })
  }

  return (
    <CatalogueGoodCreate
      catalogueType="marketplace"
      submitMarketplaceGoodCreate={submitMarketplaceGoodCreate}
    />
  )
}

export default CatalogueMarketplaceGoodCreate
