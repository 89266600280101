import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import MatchingHeader from './MatchingHeader'
import MatchingItem from '../MatchingItem/MatchingItem'
import { Empty, Loading } from '../../Shared/States/index'

import { MANUAL_MATCH_UNMATCHED } from '../query'

import * as S from '../styled'
import * as I from '../types'

const renderContent = (
  unmatched: any,
  loading: any,
  error: any,
  unmatchedItem: any,
  setUnmatchedItem: any
) => {
  if (loading) {
    return <Loading small />
  }

  if (error) {
    return <Empty />
  }

  if (unmatched && unmatched.entries > 0) {
    return <Empty />
  }

  return (
    <React.Fragment>
      {unmatched.entries.map((match: I.Match) => {
        return (
          <MatchingItem
            key={match.id}
            goodId={match.id}
            goodImage={match.images.map(img => img.large)}
            goodName={match.name}
            goodPrice={match.offers.map(off => off.price)}
            sourceName={match.offers.map(off => off.source.name)}
            sourceImage={match.offers.map(off => off.source.image.medium)}
            unmatchedItem={unmatchedItem}
            setUnmatchedItem={setUnmatchedItem}
            disabled={unmatchedItem && unmatchedItem !== match.id}
          />
        )
      })}
    </React.Fragment>
  )
}

const MatchingUnmatched = ({ unmatchedItem, setUnmatchedItem }: any) => {
  const [searchTerm, setSearchTerm] = React.useState(null)
  const { data, loading, error } = useQuery(MANUAL_MATCH_UNMATCHED, {
    variables: {
      page: 1,
      pageSize: 100,
      searchTerm: searchTerm
    }
  })

  const unmatched = data && data.results

  const handleSearch = (values: any) => {
    setSearchTerm(values.search || null)
  }

  return (
    <S.ManualMatchmaker>
      <MatchingHeader
        handleSearch={handleSearch}
        totalEntries={unmatched && unmatched.totalEntries}
      />
      <S.MatchmakerSuggestions>
        {renderContent(
          unmatched,
          loading,
          error,
          unmatchedItem,
          setUnmatchedItem
        )}
      </S.MatchmakerSuggestions>
    </S.ManualMatchmaker>
  )
}

export default MatchingUnmatched
