import React, { ReactNode } from 'react'

import * as S from './styled'

type IconLinkProps = {
  icon: ReactNode
  handleClick: () => void
}

const IconLink: React.FC<IconLinkProps> = ({
  children,
  icon,
  handleClick,
  ...props
}) => {
  return (
    <S.Holder onClick={handleClick} {...props}>
      {icon}
      <S.Text color="purple">{children}</S.Text>
    </S.Holder>
  )
}

export default IconLink
