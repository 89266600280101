import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { injectIntl, IntlShape } from 'react-intl'
import { Table } from 'cimple-web-common'

import {
  LayoutColumn,
  LayoutTables
} from 'components/Shared/Layout/LayoutDetail/LayoutDetails'
import Topbar from 'components/Shared/Navigation/Topbar/Topbar'
import { Error, Loading } from 'components/Shared/States/index'

import TableHeader from './Table/TableHeader'
import TableBody from './Table/TableBody'
import TablePagination from 'components/Shared/Pagination/Pagination'
import CatalogueMarketplaceGoodImport from 'components/CatalogueGoodImport/CatalogueMarketplaceGoodImport'

import { CATALOGUE_PAGE_SIZE } from 'constants/pageSize'
import { ERROR_RESULTS } from 'constants/errorTypes'
import { ROUTES } from 'constants/routes'

import { AFTER_LAST_SLASH } from 'utils/regex'
import { handleSearch } from 'utils/handleSearch'

import { CATALOGUE, DELETE_GOOD } from 'modules/Catalogue/Marketplace/queries'

type CatalogueMarketplaceProps = {
  intl: IntlShape
}

const CatalogueMarketplace: React.FC<CatalogueMarketplaceProps> = ({
  intl: { formatMessage: t }
}) => {
  const history = useHistory()
  const { pathname, search } = history.location

  const [page, setPage] = React.useState(1)
  const [isImportGoodsOpen, setIsImportGoodsOpen] = React.useState(false)
  const [catalogueDetailId, setCatalogueDetailId] = React.useState('')
  const [showOptions, setShowOptions] = React.useState('')
  const [searchTerm, setSearchTerm] = React.useState(search || null)

  const handleSearchWithPayload = (values: any) =>
    handleSearch(history, values, setSearchTerm, ROUTES.catalogueMarketplace)

  const handleModalClose = () => {
    history.push({
      pathname: history.location.pathname.replace('/import', '')
    })
  }

  const { data, loading, error, refetch } = useQuery(CATALOGUE, {
    variables: {
      searchTerm: searchTerm,
      page: page,
      pageSize: CATALOGUE_PAGE_SIZE
    }
  })

  const [deleteGood] = useMutation(DELETE_GOOD)

  const handleDeleteGood = () => {
    deleteGood({
      variables: {
        id: showOptions
      }
    }).then(({ data }) => {
      if (data) {
        refetch()
      }
    })
  }

  React.useEffect(() => {
    const checkForPathname = () => {
      const hasLength = pathname.match(AFTER_LAST_SLASH)

      if (hasLength && hasLength.length > 0) {
        return hasLength[0]
      }

      return null
    }

    if (checkForPathname() === 'import') {
      setIsImportGoodsOpen((isImportGoodsOpen: boolean) => !isImportGoodsOpen)
    }
  }, [pathname])

  const catalogue = data && data.results

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }

  const withActions = {
    primary: {
      actionName: 'Import goods',
      actionTo: `${ROUTES.catalogueMarketplaceGoodImport}`
    },
    secondary: {
      actionName: 'Add goods',
      actionTo: `${ROUTES.catalogueMarketplaceGoodCreate}`
    }
  }

  const withOptions = [
    {
      optionName: 'Delete',
      optionTo: () => handleDeleteGood()
    }
  ]

  if (catalogue.entries.length === 0) {
    return (
      <LayoutColumn>
        <Topbar
          pageName={t({ id: 'catalogue.catalogue' })}
          totalEntries={catalogue.totalEntries}
          name="search"
          placeholder={t({ id: 'global.search' })}
          handleSearch={handleSearchWithPayload}
          withActions={withActions}
        />
        <Error errorType={ERROR_RESULTS} />
      </LayoutColumn>
    )
  }

  return (
    <LayoutColumn>
      <Topbar
        pageName={t({ id: 'catalogue.catalogue' })}
        name="search"
        totalEntries={catalogue.totalEntries}
        placeholder={t({ id: 'global.search' })}
        handleSearch={handleSearchWithPayload}
        withActions={withActions}
      />
      {isImportGoodsOpen && (
        <CatalogueMarketplaceGoodImport
          isOpen={isImportGoodsOpen}
          setIsImportGoodsOpen={setIsImportGoodsOpen}
          onClose={(isImportGoodsOpen: boolean) => {
            handleModalClose()
            setIsImportGoodsOpen(!isImportGoodsOpen)
          }}
        />
      )}
      <LayoutTables>
        <Table>
          <TableHeader />
          <TableBody
            catalogue={catalogue}
            catalogueDetailId={catalogueDetailId}
            setCatalogueDetailId={setCatalogueDetailId}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            withOptions={withOptions}
          />
        </Table>
        <TablePagination page={page} setPage={setPage} pageName={catalogue} />
      </LayoutTables>
    </LayoutColumn>
  )
}

export default injectIntl(CatalogueMarketplace)
