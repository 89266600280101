import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import CatalogueGoodImport from './CatalogueGoodImport'

import { MP_GOOD_IMPORT_VALIDATE, MP_GOOD_IMPORT_SUBMIT } from './query'

type CatalogueMarketplaceGoodImportProps = {
  isOpen: any
  setIsImportGoodsOpen: any
  onClose: any
}

const CatalogueMarketplaceGoodImport: React.FC<
  CatalogueMarketplaceGoodImportProps
> = ({ isOpen, setIsImportGoodsOpen, onClose }) => {
  const [marketplaceGoodsImportValidate] = useMutation(MP_GOOD_IMPORT_VALIDATE)
  const [marketplaceGoodsImportSubmit] = useMutation(MP_GOOD_IMPORT_SUBMIT)

  const validateMarketplaceImport = (
    file: any,
    values: any,
    setStepNumber: any,
    setImportGoodValidateErrors: any,
    setImportGoodValidateData: any
  ) => {
    marketplaceGoodsImportValidate({
      variables: {
        file: file,
        numberOfPreviews: 5,
        supplierId: values.supplier
      }
    }).then(({ data, errors }) => {
      if (errors) {
        setStepNumber(1)
        setImportGoodValidateErrors(errors)
      }
      if (data && !errors) {
        setStepNumber(2)
        setImportGoodValidateData(data.marketplaceGoodsImportValidate)
      }
    })
  }

  const submitMarketplaceImport = (validatedValues: any) => {
    marketplaceGoodsImportSubmit({
      variables: {
        file: validatedValues.file,
        numberOfPreviews: 5,
        supplierId: validatedValues.values.supplier
      }
    }).then(({ data, errors }) => {
      if (data && !errors) {
        return setIsImportGoodsOpen()
      }
    })
  }

  return (
    <CatalogueGoodImport
      isOpen={isOpen}
      onClose={onClose}
      catalogueType="marketplace"
      validateMarketplaceImport={validateMarketplaceImport}
      submitMarketplaceImport={submitMarketplaceImport}
    />
  )
}

export default CatalogueMarketplaceGoodImport
