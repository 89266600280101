import styled from 'styled-components'
import * as UI from 'cimple-web-common'

export const Input = styled(UI.Input)`
  margin-bottom: 16px;
`
export const Button = styled(UI.Button)`
  margin: 8px 0px 16px 0px;
`

export const HeadingM = styled(UI.HeadingM)`
  margin-bottom: 1rem;
`

export const Text = styled(UI.Text)`
  margin-bottom: 0.25rem;
`

export const Error = styled(UI.Text)`
  margin-bottom: 0.25rem;
`
