import styled from 'styled-components'
import * as UI from 'cimple-web-common'

export const HolderTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  margin-top: 1rem;
`

export const HolderCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  width: 14rem;
`

export const Text = styled(UI.Text)`
  margin-bottom: 0.35rem;
`

export const Thumbnail = styled(UI.Thumbnail)`
  margin-right: 0.75em;
`

export const ThumbnailSmall = styled(UI.Thumbnail)`
  margin-right: 0.5em;
  margin-bottom: 0.5em;
`

export const ActionTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 10rem;
`

export const ActionCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
`

export const Price = styled(UI.Text)`
  width: 2.5rem;
`

export const FormHolder = styled.div`
  position: relative;
`
