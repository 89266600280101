import styled, { css } from 'styled-components'
import * as UI from 'cimple-web-common'

export const HeadingM = styled(UI.HeadingM)`
  margin-bottom: 2rem;
`

export const Title = styled(UI.Text)`
  margin-bottom: 1.5rem;
`

export const Stepper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 2rem 0rem;
`

export const Inline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Line = styled.hr`
  display: block;
  height: 1px;
  border-style: dashed;
  border-width: 0.5px;
  width: 100%;

  color: ${({ theme }) => theme.colors.greyscale.light};
`

export const InputSmall = styled(UI.Input)`
  min-width: 14rem;
`

export const InputNoMinWidth = styled(UI.Input)`
  min-width: 0rem;
`

/* Main Info Step */

export const InputTitle = styled(InputNoMinWidth)`
  grid-area: title;
`

export const InputSku = styled(InputNoMinWidth)`
  grid-area: sku;
`
export const InputPrice = styled(InputNoMinWidth)`
  grid-area: price;
`

export const InputDiscountedPrice = styled(InputNoMinWidth)`
  grid-area: discountedprice;
`
export const InputVat = styled(InputNoMinWidth)`
  grid-area: vat;
`
export const SelectCategory = styled(UI.Select)`
  grid-area: category;
  width: 100%;
`

export const SelectUnitType = styled(UI.Select)`
  grid-area: unit;
  width: 100%;
`

export const SelectSupplier = styled(UI.Select)`
  grid-area: supplier;
  width: 100%;
`

export const SelectSource = styled(UI.Select)`
  grid-area: source;
  width: 100%;
`

export const SelectUrl = styled(InputNoMinWidth)`
  grid-area: url;
  width: 100%;
`

export const SelectExternalId = styled(InputNoMinWidth)`
  grid-area: externalId;
  width: 100%;
`

export const InputDescription = styled(UI.TextArea)`
  grid-area: description;
`
export const InputExternalId = styled(InputNoMinWidth)`
  grid-area: externalId;
`

export const Grid = styled.div<{ catalogueType?: any }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem 1rem;
  grid-template-areas:
    'title title sku sku'
    'price vat unit unit'
    'category category supplier supplier'
    'description description description description'
    'externalId externalId externalId externalId';

  ${p =>
    p.catalogueType === 'signpost' &&
    css`
      grid-template-areas:
        'title title sku sku'
        'price vat unit unit'
        'category category source source'
        'url url url externalId'
        'description description description description';
    `}
`

/* Specifications Step */

export const KeyValue = styled(Inline)`
  padding-bottom: 1rem;
`
