import React from 'react'
import { Pagination } from 'cimple-web-common'

import { PaginationHolder } from 'components/Shared/Layout/LayoutDetail/LayoutDetails'

import { safeSetPage } from 'utils/safeSetPage'

type Page = {
  page: number
  setPage: (newPage: number) => void
  pageName: {
    pageNumber: number
    pageSize: number
    totalEntries: number
    totalPages: number
  }
}

const TablePagination = ({ page, setPage, pageName }: Page) => {
  return (
    <PaginationHolder>
      <Pagination
        current={page}
        total={pageName.totalPages}
        onChange={(newPage: number) => {
          safeSetPage(newPage, setPage, pageName.totalPages)
        }}
      />
    </PaginationHolder>
  )
}

export default TablePagination
