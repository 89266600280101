import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import MatchingManual from '../components/MatchingManual/MatchingManual'

const MatchingManualView = () => {
  return (
    <Layout>
      <MatchingManual />
    </Layout>
  )
}

export default MatchingManualView
