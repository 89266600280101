import React from 'react'
import { Formik, FormikProps } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { injectIntl, IntlShape } from 'react-intl'
import { useHistory } from 'react-router-dom'

import {
  LayoutCRUD,
  LayoutModal
} from 'components/Shared/Layout/LayoutDetail/LayoutDetails'
import Dropzone from 'components/Shared/Dropzone/Dropzone'
import { Button, HeadingM } from 'cimple-web-common'

import { addSourceValidation } from 'utils/validation/addSourceValidation'

import { CREATE_SOURCE } from 'modules/Sources/queries'

import * as S from './styled'

import { ROUTES } from 'constants/routes'

type Values = {
  sourceName: string
  sourceEmail: string
  sourceWebsite: string
  sourceDescription: string
}

type SourcesAddProps = {
  intl: IntlShape
}

const SourcesAdd: React.FC<SourcesAddProps> = ({
  intl: { formatMessage: t }
}) => {
  const history = useHistory()
  const [createSource, { error, loading }] = useMutation(CREATE_SOURCE)
  const [dropzoneLocalFiles, setDropzoneLocalFiles] = React.useState([])

  const handleSubmission = (values: Values) => {
    createSource({
      variables: {
        input: {
          name: values.sourceName,
          email: values.sourceEmail,
          description: values.sourceDescription,
          website: values.sourceWebsite
        },
        image: dropzoneLocalFiles[0]
      }
    }).then((data: any) => {
      if (data && data.signpostSourceCreate) {
        history.push({ pathname: ROUTES.sources })
      }
    })
  }

  const initialValues = {
    sourceName: '',
    sourceEmail: '',
    sourceWebsite: '',
    sourceDescription: ''
  }

  type Values = typeof initialValues

  return (
    <LayoutModal>
      <LayoutCRUD>
        <HeadingM>{t({ id: 'sources.addSource' })}</HeadingM>
        <Formik
          initialValues={{
            sourceName: '',
            sourceEmail: '',
            sourceWebsite: '',
            sourceDescription: ''
          }}
          validate={addSourceValidation}
          onSubmit={(values: Values) => handleSubmission(values)}
          render={({
            errors,
            isValid,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }: FormikProps<Values>) => {
            return (
              <S.Form onSubmit={handleSubmit}>
                <div>
                  <S.FormInputs>
                    <S.Input
                      label={t({ id: 'sources.sourceName' })}
                      type="text"
                      name="sourceName"
                      placeholder={t({
                        id: 'sources.sourceNamePlaceholder'
                      })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sourceName}
                      error={errors.sourceName && touched.sourceName}
                    />
                    <S.Input
                      label={t({ id: 'sources.emailAddress' })}
                      type="email"
                      name="sourceEmail"
                      placeholder={t({
                        id: 'sources.emailPlaceholder'
                      })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sourceEmail}
                      error={errors.sourceEmail && touched.sourceEmail}
                    />
                  </S.FormInputs>
                  <Dropzone
                    singleFile
                    dropzoneLabel={t({ id: 'sources.sourceLogo' })}
                    dropzoneLocalFiles={dropzoneLocalFiles}
                    setDropzoneLocalFiles={setDropzoneLocalFiles}
                  />
                  <S.FormInputs>
                    <S.Input
                      label={t({ id: 'sources.website' })}
                      type="text"
                      name="sourceWebsite"
                      placeholder={t({ id: 'sources.websitePlaceholder' })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sourceWebsite}
                      error={errors.sourceWebsite && touched.sourceWebsite}
                    />
                    <S.Input
                      label={t({ id: 'sources.description' })}
                      type="text"
                      name="sourceDescription"
                      placeholder={t({ id: 'sources.descriptionPlaceholder' })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sourceDescription}
                      error={
                        errors.sourceDescription && touched.sourceDescription
                      }
                    />
                  </S.FormInputs>
                </div>

                {error &&
                  error.graphQLErrors.map((err: any) => {
                    return (
                      <S.Error color="red" key={err.message}>
                        {err.message}
                      </S.Error>
                    )
                  })}

                <S.FormActions>
                  <Button
                    disabled={!(isValid && dropzoneLocalFiles.length !== 0)}
                    isLoading={loading}
                    type="submit"
                  >
                    {t({ id: 'sources.confirm' })}
                  </Button>
                </S.FormActions>
              </S.Form>
            )
          }}
        />
      </LayoutCRUD>
    </LayoutModal>
  )
}

export default injectIntl(SourcesAdd)
