import React from 'react'
import { Icons, Text } from 'cimple-web-common'

import * as S from './styled'

type ArrowLinkProps = {
  to: string
}

const ArrowLink: React.FC<ArrowLinkProps> = ({ children, to, ...props }) => {
  return (
    <S.Holder>
      <S.Link to={to} {...props}>
        <Text>{children}</Text>
        <Icons.Arrow />
      </S.Link>
    </S.Holder>
  )
}

export default ArrowLink
