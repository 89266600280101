import React from 'react'
import { injectIntl } from 'react-intl'

import { Button, Text } from 'cimple-web-common'

import * as S from '../styled'
import * as I from '../types'

const PreviewGoodsError = ({
  importGoodValidateErrors,
  setStepNumber
}: I.PreviewGoodsErrorProps) => {
  const errorNumber = importGoodValidateErrors.length - 1

  const parseErrorLine = (errMessage: { match: Function }) => {
    const errorLine = errMessage.match(/\[\d\]/g)[0]
    return errorLine
  }

  const parseErrorMessage = (errMessage: string) => {
    const parsedError = errMessage.replace(/\[\d\]/g, '')
    return parsedError
  }

  return (
    <div>
      <S.HeadingM>Error uploading goods</S.HeadingM>
      <S.Text as="span">
        {`The csv uploaded has ${errorNumber} errors. Please address the following lines.`}
      </S.Text>{' '}
      {importGoodValidateErrors.length > 0 && (
        <S.ErrorHolder>
          {importGoodValidateErrors.map((err: any, i: number) => {
            return (
              <S.Error key={i}>
                <S.Flex>
                  {err.locations.map((_: any, i: number) => (
                    <Text bold key={i}>
                      Error in line {parseErrorLine(err.message)}
                    </Text>
                  ))}
                </S.Flex>
                <S.ErrorDescription>
                  {parseErrorMessage(err.message)}
                </S.ErrorDescription>
              </S.Error>
            )
          })}
        </S.ErrorHolder>
      )}
      <Button onClick={(stepNumber: number) => setStepNumber(stepNumber - 1)}>
        Back to import
      </Button>
    </div>
  )
}

export default injectIntl(PreviewGoodsError)
