import React from 'react'
import { UppercaseL, Text, TagSecondary, Checkbox } from 'cimple-web-common'

import * as S from './styled'

type MatchedItemProps = {
  goodId: any
  goodImage: any
  goodName: any
  offers: any
  similarityPercentage: any
  matchedItems: any
  handleMatchedItems: any
}

const MatchedItem: React.FC<MatchedItemProps> = ({
  goodId,
  goodImage,
  goodName,
  offers,
  similarityPercentage,
  matchedItems,
  handleMatchedItems
}) => {
  if (offers.length > 1) {
    return (
      <S.HolderTop key={goodId}>
        <S.Flex style={{ alignItems: 'flex-start' }}>
          <S.Thumbnail size="large" src={goodImage} />
          <S.Column>
            <S.Text>{goodName}</S.Text>
            {offers.map((off: any, i: number) => {
              return (
                <S.Flex key={i}>
                  <S.Flex>
                    <S.ThumbnailSmall
                      size="mini"
                      src={off.source.image.medium}
                    />
                    <UppercaseL>{off.source.name}</UppercaseL>
                  </S.Flex>
                  <Text>{off.price}</Text>
                </S.Flex>
              )
            })}
          </S.Column>
        </S.Flex>
        <S.ActionTop>
          <TagSecondary value={similarityPercentage}>
            {similarityPercentage}
          </TagSecondary>
          <Checkbox
            checked={matchedItems.indexOf(goodId) >= 0}
            onChange={() => handleMatchedItems(goodId)}
          />
        </S.ActionTop>
      </S.HolderTop>
    )
  }

  return (
    <S.HolderCenter key={goodId}>
      <S.Flex>
        <S.Thumbnail size="large" src={goodImage} />
        <S.Column>
          <S.Text>{goodName}</S.Text>
          {offers.map((off: any, i: number) => {
            return (
              <S.Flex key={i}>
                <S.Flex>
                  <S.ThumbnailSmall size="mini" src={off.source.image.medium} />
                  <UppercaseL>{off.source.name}</UppercaseL>
                </S.Flex>
                <Text>{off.price}</Text>
              </S.Flex>
            )
          })}
        </S.Column>
      </S.Flex>
      <S.ActionCenter>
        <TagSecondary value={similarityPercentage}>
          {similarityPercentage}
        </TagSecondary>
        <Checkbox
          checked={matchedItems.indexOf(goodId) >= 0}
          onChange={() => handleMatchedItems(goodId)}
        />
      </S.ActionCenter>
    </S.HolderCenter>
  )
}

export default MatchedItem
