import styled from 'styled-components'

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SourceItem = styled(Flex)`
  div {
    margin-right: 0.75rem;
  }
`
export const Edit = styled.div`
  &:hover {
    cursor: pointer;

    background-color: ${({ theme }) => theme.colors.greyscale.lighter};
    border-radius: ${({ theme }) => theme.radius.small};
    max-width: 1.5rem;
  }
`
