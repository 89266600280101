import React from 'react'
import { useHistory } from 'react-router-dom'
import { Icons } from 'cimple-web-common'

import { ROUTES } from 'constants/routes'
import * as S from './styled'

type Option = { optionName: string; optionTo: string }
type OptionsProps = {
  isOpen: boolean
  handleClick: () => void
  options: Array<Option>
}

const Options: React.FC<OptionsProps> = ({ isOpen, handleClick, options }) => {
  const history = useHistory()

  const handleOptionType = (optionTo: Function | string) => {
    if (typeof optionTo === 'function') {
      return optionTo
    }

    return history.push({ pathname: ROUTES.sources })
  }

  return (
    <S.Options onClick={handleClick} isOpen={isOpen}>
      <Icons.Ellipsis />

      {isOpen && (
        <S.Popover>
          {options.map((option: Option) => {
            return (
              <S.PopoverItem
                key={option.optionName}
                onClick={() => handleOptionType(option.optionTo)}
              >
                {option.optionName}
              </S.PopoverItem>
            )
          })}
        </S.Popover>
      )}
    </S.Options>
  )
}

export default Options
