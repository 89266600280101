import React from 'react'
import { Formik } from 'formik'

import { Text } from 'cimple-web-common'
import * as S from '../styled'

type MatchingHeaderProps = {
  handleSearch: any
  totalEntries: any
}

export const MatchingHeader: React.FC<MatchingHeaderProps> = ({
  handleSearch,
  totalEntries
}) => {
  return (
    <S.MatchmakerHeader>
      <S.Flex>
        <Text bold>Select or search for a good to match</Text>
        <Text>{totalEntries ? totalEntries : '...'} goods unmatched</Text>
      </S.Flex>
      <Formik
        initialValues={{ search: '' }}
        onSubmit={values => handleSearch(values)}
        render={({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <S.Searchbar
                name="search"
                placeholder="Search"
                type="input"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.search}
                error={errors.search && touched.search}
              />
            </form>
          )
        }}
      />
    </S.MatchmakerHeader>
  )
}

export default MatchingHeader
