type Values = {
  supplierName: string
  supplierEmail: string
}

export const addSupplierValidation = (values: Values) => {
  const errors: { [key: string]: string } = {}

  if (!values.supplierName) {
    errors.supplierName = ''
  }

  if (!values.supplierEmail) {
    errors.supplierEmail = ''
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.supplierEmail)
  ) {
    errors.supplierEmail = 'Invalid email'
  }

  return errors
}
