type ValuesMarketplace = {
  goodName: string
  goodSku: string
  goodPrice: string
  goodVAT: string
  goodUnitType: string
  goodCategory: string
  goodSupplier: string
  goodDescription: string
}

type ValuesSignpost = {
  goodName: string
  goodSku: string
  goodPrice: string
  goodVAT: string
  goodUnitType: string
  goodCategory: string
  goodSource: string
  goodUrl: string
  goodExternalId: string
  goodDescription: string
}

export const addGoodValidationMarketplace = (values: ValuesMarketplace) => {
  const errors: { [key: string]: string } = {}

  if (!values.goodName) {
    errors.goodName = ''
  }

  if (!values.goodSku) {
    errors.goodSku = ''
  }

  if (!values.goodPrice) {
    errors.goodPrice = ''
  }

  if (!values.goodVAT) {
    errors.goodVAT = ''
  }

  if (!values.goodUnitType) {
    errors.goodUnitType = ''
  }

  if (!values.goodCategory) {
    errors.goodCategory = ''
  }

  if (!values.goodSupplier) {
    errors.goodSupplier = ''
  }

  if (!values.goodDescription) {
    errors.goodDescription = ''
  }

  return errors
}

export const addGoodValidationSignpost = (values: ValuesSignpost) => {
  const errors: { [key: string]: string } = {}

  if (!values.goodName) {
    errors.goodName = ''
  }

  if (!values.goodSku) {
    errors.goodSku = ''
  }

  if (!values.goodPrice) {
    errors.goodPrice = ''
  }

  if (!values.goodVAT) {
    errors.goodVAT = ''
  }

  if (!values.goodUnitType) {
    errors.goodUnitType = ''
  }

  if (!values.goodCategory) {
    errors.goodCategory = ''
  }

  if (!values.goodSource) {
    errors.goodSource = ''
  }

  if (!values.goodUrl) {
    errors.goodUrl = ''
  }

  if (!values.goodExternalId) {
    errors.goodExternalId = ''
  }

  if (!values.goodDescription) {
    errors.goodDescription = ''
  }

  return errors
}
