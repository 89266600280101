import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import Sources from '../components/Sources/Sources'

const SourcesView = () => {
  return (
    <Layout>
      <Sources />
    </Layout>
  )
}

export default SourcesView
