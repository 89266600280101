type Values = {
  email: string
  password: string
}

export const signInValidation = (values: Values) => {
  const errors: { [key: string]: string } = {}

  if (!values.email) {
    errors.email = ''
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email'
  }

  if (!values.password) {
    errors.password = ''
  }

  return errors
}
