export type Status =
  | 'CANCELED'
  | 'COMPLETED'
  | 'DISPATCHED'
  | 'ONGOING'
  | 'REFUNDED'

export const getStatus = (status: Status) => {
  const Uppercase = status.charAt(0).toUpperCase()
  const str = status.toLowerCase().slice(1)
  return Uppercase + str
}
