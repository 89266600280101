import styled, { css } from 'styled-components'
import * as UI from 'cimple-web-common'

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CatalogueItem = styled(Flex)`
  cursor: pointer;
  div {
    margin-right: 0.75rem;
  }
`

export const FlexStretch = styled(Flex)`
  justify-content: space-between;
`

export const Modal = styled(UI.Modal)`
  width: 100%;
  max-width: 24rem;
  max-height: 94vh;
  overflow: scroll;
`

export const HeadingM = styled(UI.HeadingM)`
  margin-bottom: 2rem;
`

export const HeadingS = styled(UI.HeadingS)`
  margin-bottom: 0.5rem;
`

export const Tag = styled(UI.Tag)`
  margin-bottom: 0.75rem;
`

export const UppercaseL = styled(UI.UppercaseL)`
  margin-bottom: 0.5rem;
`

export const Text = styled(UI.Text)`
  margin-bottom: 1rem;
`

export const Description = styled(UI.Text)`
  margin-bottom: 2rem;
`

export const Image = styled.div<{ src?: any }>`
  background-color: ${({ theme }) => theme.colors.greyscale.lighter};
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center center;

  margin-bottom: 2rem;

  height: 14rem;
  width: 100%;
`

export const ModalSpecifications = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.greyscale.lighter};

  width: 100%;
`

export const Options = styled.div<{ isOpen: boolean }>`
  position: relative;
  border-radius: ${({ theme }) => theme.radius.small};
  max-width: 1.5rem;

  &:hover {
    cursor: pointer;

    background-color: ${({ theme }) => theme.colors.greyscale.lighter};
  }

  ${p =>
    p.isOpen &&
    css`
      background-color: ${({ theme }) => theme.colors.greyscale.lighter};
    `}
`

export const OptionsPopover = styled.div`
  background-color: ${({ theme }) => theme.colors.greyscale.white};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.greyscale.light};
  max-width: 8rem;

  padding: 1rem 0.75rem;

  position: absolute;
  top: 2rem;
  left: -2rem;
  z-index: 999;

  -webkit-box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
  box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
`

export const GoodList = styled(UI.GoodList)`
  cursor: pointer;
`
