import gql from 'graphql-tag'

export const MANUAL_MATCH_UNMATCHED = gql`
  query manualUnmatched($searchTerm: String, $page: Int!, $pageSize: Int!) {
    results: signpostMatchingsManualUnmatched(
      pagination: { page: $page, pageSize: $pageSize }
      searchTerm: $searchTerm
    ) {
      totalEntries
      entries {
        id
        name
        images {
          small
          large
        }
        offers {
          price
          source {
            name
            image {
              medium
              small
            }
          }
        }
      }
    }
  }
`

export const MANUAL_MATCH_SUGGESTED = gql`
  query manualSuggested(
    $goodId: ID!
    $searchTerm: String
    $page: Int!
    $pageSize: Int!
  ) {
    results: signpostMatchingsManualSuggested(
      goodId: $goodId
      pagination: { page: $page, pageSize: $pageSize }
      searchTerm: $searchTerm
    ) {
      totalEntries
      entries {
        id
        similarity
        name
        images {
          large
        }
        offers {
          price
          source {
            name
            image {
              medium
              small
            }
          }
        }
      }
    }
  }
`

export const MANUAL_MATCH = gql`
  mutation manualMatch($goodId: ID!, $goodsIds: [ID!]) {
    results: signpostMatchingsManualMatch(
      goodId: $goodId
      goodsIds: $goodsIds
    ) {
      id
    }
  }
`
