import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider, useQuery } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'
import { Provider as AlertProvider } from 'react-alert'
import LocaleProvider from 'intl/intl'

import { Alert, Provider } from 'cimple-web-common'
import { Loading } from 'components/Shared/States/index'

import Routes from 'routes/Routes'
import SignIn from 'pages/SignIn'
import { apolloClient } from 'lib/apolloClient'

import { useWorkingAlerts } from 'hooks/useWorkingAlerts'

import { MEMBER_DETAILS } from 'modules/Authentication/queries'

const root = document.getElementById('root')

const App = () => {
  useWorkingAlerts()
  const { data, loading, error } = useQuery(MEMBER_DETAILS)
  const userLoggedIn = data && data.me && data.me.user && data.me.user.id

  if (loading) {
    return <Loading />
  }

  if (error && !userLoggedIn) {
    return <SignIn />
  }

  return <Routes />
}

if (root !== null) {
  ReactDOM.render(
    <Provider>
      <ApolloProvider client={apolloClient}>
        <LocaleProvider>
          <AlertProvider template={Alert}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AlertProvider>
        </LocaleProvider>
      </ApolloProvider>
    </Provider>,
    root
  )
}
