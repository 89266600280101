import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import MatchingSuggested from '../components/MatchingSuggested/MatchingSuggested'

const MatchingSuggestedView = () => {
  return (
    <Layout>
      <MatchingSuggested />
    </Layout>
  )
}

export default MatchingSuggestedView
