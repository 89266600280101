import React from 'react'

import { injectIntl, IntlShape } from 'react-intl'
import { useQuery } from '@apollo/react-hooks'

import ImportGoods from './ImportGoodsSteps/ImportGoods'
import PreviewGoods from './ImportGoodsSteps/PreviewGoods'
import PreviewGoodsError from './ImportGoodsSteps/PreviewGoodsError'

import { Loading } from 'components/Shared/States/index'

import { ALL_SUPPLIERS, ALL_SOURCES } from './query'

import * as S from './styled'

import { CatalogueType } from 'modules/Global/catalogueType'

type CatalogueMarketplaceImportProps = {
  intl: IntlShape
  catalogueType: CatalogueType
  isOpen: boolean
  onClose: () => void
  validateMarketplaceImport?: (
    file: any,
    values: any,
    setStepNumber: any,
    setImportGoodValidateErrors: any,
    setImportGoodValidateData: any
  ) => void
  validateSignpostImport?: (
    file: any,
    values: any,
    setStepNumber: any,
    setImportGoodValidateErrors: any,
    setImportGoodValidateData: any
  ) => void
  submitMarketplaceImport?: (validatedValues: any) => void
  submitSignpostImport?: (validatedValues: any) => void
}

const CatalogueGoodImport: React.FC<CatalogueMarketplaceImportProps> = ({
  isOpen,
  onClose,
  catalogueType,
  validateMarketplaceImport,
  submitMarketplaceImport,
  validateSignpostImport,
  submitSignpostImport
}) => {
  const {
    data: allSuppliers,
    loading: suppliersLoading,
    error: suppliersError
  } = useQuery(ALL_SUPPLIERS)
  const {
    data: allSources,
    loading: sourcesLoading,
    error: sourcesError
  } = useQuery(ALL_SOURCES)

  const [stepNumber, setStepNumber] = React.useState(0)
  const [validatedValues, setValidatedValues] = React.useState({})
  const [importGoodValidateData, setImportGoodValidateData] = React.useState([])
  const [
    importGoodValidateErrors,
    setImportGoodValidateErrors
  ] = React.useState([])

  // [0] Validation stage
  const handleImportValidate = (values: any, file: any) => {
    setValidatedValues({ values, file })

    const handleValidate: { [key in CatalogueType]: () => any } = {
      marketplace: () =>
        validateMarketplaceImport &&
        validateMarketplaceImport(
          file,
          values,
          setStepNumber,
          setImportGoodValidateErrors,
          setImportGoodValidateData
        ),
      signpost: () =>
        validateSignpostImport &&
        validateSignpostImport(
          file,
          values,
          setStepNumber,
          setImportGoodValidateErrors,
          setImportGoodValidateData
        )
    }

    return handleValidate[catalogueType]()
  }

  // [1] Submission stage
  const handleImportSubmit = () => {
    const handleSubmit: { [key in CatalogueType]: () => any } = {
      marketplace: () =>
        submitMarketplaceImport && submitMarketplaceImport(validatedValues),
      signpost: () =>
        submitSignpostImport && submitSignpostImport(validatedValues)
    }

    return handleSubmit[catalogueType]()
  }

  if (suppliersLoading || sourcesLoading) {
    return <Loading small />
  }

  if (suppliersError || sourcesError) {
    // TODO: Create empty state
    return <p>There was an error</p>
  }

  const stepMap = [
    <ImportGoods
      key="importGoods"
      catalogueType={catalogueType}
      allSuppliers={allSuppliers}
      allSources={allSources}
      handleImportValidate={handleImportValidate}
    />,
    <PreviewGoodsError
      key="previewGoodsError"
      importGoodValidateErrors={importGoodValidateErrors}
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
    />,
    <PreviewGoods
      key="previewGoodsSuccess"
      importGoodValidateData={importGoodValidateData}
      handleImportSubmit={handleImportSubmit}
    />
  ]

  const renderStep = (stepNumber: number) => {
    return stepMap[stepNumber] || stepMap[0]
  }

  return (
    <S.Modal isOpen={isOpen} onClose={onClose}>
      {renderStep(stepNumber)}
    </S.Modal>
  )
}

export default injectIntl(CatalogueGoodImport)
