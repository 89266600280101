import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import OrderHistory from '../components/OrderHistory/OrderHistory'

const OrderHistoryView = () => {
  return (
    <Layout>
      <OrderHistory />
    </Layout>
  )
}

export default OrderHistoryView
