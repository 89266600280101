import styled from 'styled-components'
import { Text as TextStyled } from 'cimple-web-common'

export const Holder = styled.div`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
`

export const Text = styled(TextStyled)`
  margin-left: 8px;
`
