import styled from 'styled-components'

export const Holder = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  max-width: 320px;
  margin-top: 120px;

  @media only screen and (max-width: 375px) {
    margin: 280px;
    margin: 60px 20px 60px 20px;
  }
`
