import styled from 'styled-components'
import * as UI from 'cimple-web-common'

export const Holder = styled.div`
  padding: 6rem 2rem 2rem 2rem;
`

export const MatchingPairs = styled(UI.MatchingPairs)`
  margin-bottom: 1rem;
`
