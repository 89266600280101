import styled from 'styled-components'

export const LayoutTables = styled.div`
  width: 100%;
  padding: 5rem 2rem 2rem 2rem;
`

export const LayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;

  width: 100%;
`

export const PaginationHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;

  width: 100%;
`

export const LayoutModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  margin-top: 4rem;
  width: 100%;
`

export const LayoutCRUD = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 30rem;
  width: 100%;
  height: 100%;
`
