import React from 'react'
import { Table, Thumbnail } from 'cimple-web-common'

import Options from 'components/Shared/Options/Options'
import ArrowLink from 'components/Shared/Links/ArrowLink/ArrowLink'
import CatalogueGoodDetail from 'components/CatalogueGoodDetail/CatalogueGoodDetail'

import * as S from '../styled'

import { CatalogueItem } from 'modules/Catalogue/ItemDetail'
import { ROUTES } from 'constants/routes'

type TableBody = {
  catalogue: any
  catalogueDetailId: any
  setCatalogueDetailId: any
  showOptions: any
  setShowOptions: any
  withOptions: any
}

const TableBody: React.FC<TableBody> = ({
  catalogue,
  catalogueDetailId,
  setCatalogueDetailId,
  showOptions,
  setShowOptions,
  withOptions
}) => {
  const renderModal = (catalogueItem: CatalogueItem) => {
    return (
      <CatalogueGoodDetail
        isOpen={!!catalogueDetailId}
        onClose={() => setCatalogueDetailId(!!catalogueDetailId)}
        catalogueItem={catalogueItem}
      />
    )
  }

  return (
    <Table.Body>
      {catalogue.entries.map((catalogueItem: CatalogueItem) => {
        const imageUrl = catalogueItem.images.map(
          (image: { small: string }) => image.small
        )

        return (
          <Table.Row key={catalogueItem.id}>
            {/* Render Good Detail  */}
            {catalogueItem.id === catalogueDetailId
              ? renderModal(catalogueItem)
              : null}
            <Table.Cell>
              <S.GoodList
                onClick={() => setCatalogueDetailId(catalogueItem.id)}
                goodImage={imageUrl}
                goodName={catalogueItem.name}
                noSku
                small
              />
            </Table.Cell>
            <Table.Cell>
              <S.SourceColumn>
                {catalogueItem.offers.map(off => {
                  return (
                    <S.Source key={off.id}>
                      <Thumbnail src={off.source.image.small} size="small" />
                      <p>{off.source.name}</p>
                    </S.Source>
                  )
                })}
              </S.SourceColumn>
            </Table.Cell>
            <Table.Cell>
              {catalogueItem.offers.map(off => {
                return (
                  <S.Source key={off.id}>
                    <p>{off.price}</p>
                  </S.Source>
                )
              })}
            </Table.Cell>
            <Table.Cell>
              <ArrowLink to={ROUTES.matching}>Match now</ArrowLink>
            </Table.Cell>
            <Table.Cell>
              <Options
                handleClick={() => setShowOptions(catalogueItem.id)}
                isOpen={showOptions === catalogueItem.id}
                options={withOptions}
              />
            </Table.Cell>
          </Table.Row>
        )
      })}
    </Table.Body>
  )
}

export default TableBody
