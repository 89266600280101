import styled from 'styled-components'
import * as UI from 'cimple-web-common'

import { NavLink as StyledNavLink } from 'react-router-dom'

export const Text = styled(UI.Text)``

export const Arrow = styled(UI.Icons.Arrow)`
  position: absolute;
  left: -1rem;
`

export const Holder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  color: ${({ theme }) => theme.colors.brand.default};

  ${Arrow} {
    display: none;
  }

  .active {
    color: ${({ theme }) => theme.colors.brand.default};

    ${Text} {
      color: ${({ theme }) => theme.colors.greyscale.white};
    }

    ${Arrow} {
      display: block;
    }
  }
`

export const Link = styled(UI.Text)``

export const NavLink = styled(StyledNavLink)`
  color: ${({ theme }) => theme.colors.brand.default};
  display: flex;
  flex-direction: row;
  align-items: center;
`
