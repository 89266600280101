import gql from 'graphql-tag'

// TODO: Change query to not use pagination.

export const ALL_SUPPLIERS = gql`
  query getAllSuppliers {
    marketplaceSuppliersList(pagination: { page: 1, pageSize: 1000000 }) {
      entries {
        supplier {
          id
          name
        }
      }
    }
  }
`

// TODO: Change query to not use pagination.

export const ALL_SOURCES = gql`
  query getAllSuppliers {
    signpostSourcesList(pagination: { page: 1, pageSize: 1000000 }) {
      entries {
        id
        name
      }
    }
  }
`

// MARKETPLACE
// VALIDATION AND SUBMISSION

export const MP_GOOD_IMPORT_VALIDATE = gql`
  mutation marketplaceGoodsImportValidate(
    $file: Upload!
    $numberOfPreviews: Int!
    $supplierId: ID!
  ) {
    marketplaceGoodsImportValidate(
      file: $file
      numberOfPreviews: $numberOfPreviews
      supplierId: $supplierId
    ) {
      previews {
        name
        image
        price
        sku
        vat
      }
      quota
    }
  }
`

export const MP_GOOD_IMPORT_SUBMIT = gql`
  mutation marketplaceGoodsImport(
    $file: Upload!
    $numberOfPreviews: Int!
    $supplierId: ID!
  ) {
    marketplaceGoodsImport(
      file: $file
      numberOfPreviews: $numberOfPreviews
      supplierId: $supplierId
    ) {
      previews {
        name
        image
        price
        sku
        vat
      }
      quota
    }
  }
`

// SIGNPOST
// VALIDATION AND SUBMISSION

export const SP_GOOD_IMPORT_VALIDATE = gql`
  mutation signpostGoodsImportValidate(
    $file: Upload!
    $numberOfPreviews: Int!
    $sourceId: ID!
  ) {
    signpostGoodsImportValidate(
      file: $file
      numberOfPreviews: $numberOfPreviews
      sourceId: $sourceId
    ) {
      previews {
        name
        image
        price
        sku
        vat
      }
      quota
    }
  }
`

export const SP_GOOD_IMPORT_SUBMIT = gql`
  mutation signpostGoodsImport(
    $file: Upload!
    $numberOfPreviews: Int!
    $sourceId: ID!
  ) {
    signpostGoodsImport(
      file: $file
      numberOfPreviews: $numberOfPreviews
      sourceId: $sourceId
    ) {
      previews {
        name
        image
        price
        sku
        vat
      }
      quota
    }
  }
`

// GLOBAL IMPORTS

export const SP_IS_IMPORTING = gql`
  query {
    signpostTasksInformation {
      quota
      quota_ongoing
      is_working
    }
  }
`

export const MP_IS_IMPORTING = gql`
  query {
    marketplaceTasksInformation {
      quota
      quota_ongoing
      is_working
    }
  }
`
