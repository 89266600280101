import { CatalogueType } from 'modules/Global/catalogueType'

type ValuesMarketplace = {
  supplier: string
}

type ValuesSignpost = {
  source: string
}

export const importGoodMarketplaceValidation = (
  values: ValuesMarketplace,
  catalogueType: CatalogueType
) => {
  if (catalogueType === 'marketplace') {
    const errors: any = {}

    if (!values.supplier) {
      errors.supplier = ''
    }

    return errors
  }

  return null
}

export const importGoodSignpostValidation = (
  values: ValuesSignpost,
  catalogueType: CatalogueType
) => {
  if (catalogueType === 'signpost') {
    const errors: any = {}

    if (!values.source) {
      errors.source = ''
    }

    return errors
  }

  return null
}
