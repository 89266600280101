import React from 'react'
import * as S from './styled'
import noMatches from '../../../../assets/cimple-noMatches@3x.png'

const Empty = () => {
  return (
    <S.Empty>
      <S.EmptyImage src={noMatches} alt="An error has occured." />
      <S.Text bold>
        Select or search for a good to match on the left panel.
      </S.Text>
    </S.Empty>
  )
}

export default Empty
