import React from 'react'
import { injectIntl } from 'react-intl'
import { Formik, Form, FormikProps } from 'formik'
import Stepper from './Stepper'
import Loading from 'components/Shared/States/Loading/Loading'

import {
  addGoodValidationMarketplace,
  addGoodValidationSignpost
} from 'utils/validation/addGoodValidation'

import * as S from '../styled'
import * as I from 'modules/Catalogue/GoodCreate/types'

import { CatalogueType } from 'modules/Global/catalogueType'

const StepProductInfo = ({
  intl: { formatMessage: t },
  allCategories,
  allSuppliers,
  allUnitTypes,
  allSources,
  catalogueType,
  stepNumber,
  setStepNumber,
  submitStep
}: I.StepProductInfoProps) => {
  const categoriesData =
    !allCategories ||
    !allCategories.allCategories ||
    allCategories.allCategories.length === 0

  const supplierData =
    !allSuppliers ||
    !allSuppliers.marketplaceSuppliersList ||
    allSuppliers.marketplaceSuppliersList.entries.length === 0

  const unitTypes =
    !allUnitTypes ||
    !allUnitTypes.marketplaceGoodsUnitTypes ||
    allUnitTypes.marketplaceGoodsUnitTypes.length === 0

  const sources =
    !allSources ||
    !allSources.signpostSourcesList ||
    allSources.signpostSourcesList.length === 0

  if (categoriesData || supplierData || unitTypes || sources) {
    return <Loading />
  }

  const intialValuesMarketplace = {
    goodName: '',
    goodSku: '',
    goodPrice: '',
    goodVAT: '',
    goodUnitType: '',
    goodCategory: '',
    goodSupplier: '',
    goodDescription: '',
    goodExternalId: ''
  }

  const initialValuesSignpost = {
    goodName: '',
    goodSku: '',
    goodPrice: '',
    goodVAT: '',
    goodUnitType: '',
    goodCategory: '',
    goodSource: '',
    goodUrl: '',
    goodExternalId: '',
    goodDescription: ''
  }

  type ValuesMarketplace = typeof intialValuesMarketplace
  type ValuesSignpost = typeof initialValuesSignpost

  const renderForm = (catalogueType: CatalogueType) => {
    const map = {
      marketplace: <MarketplaceForm />,
      signpost: <SignpostForm />
    }

    return map[catalogueType]
  }

  const MarketplaceForm = () => {
    return (
      <Formik
        initialValues={intialValuesMarketplace}
        validate={(values: ValuesMarketplace) =>
          addGoodValidationMarketplace(values)
        }
        onSubmit={(values: ValuesMarketplace) => submitStep(values)}
        render={({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isValid
        }: FormikProps<ValuesMarketplace>) => {
          return (
            <Form>
              <S.Title bold>Main Info</S.Title>
              <S.Grid catalogueType={catalogueType}>
                <S.InputTitle
                  label={t({
                    id: 'catalogueMarketplace.goodName'
                  })}
                  type="text"
                  name="goodName"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodNamePlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodName}
                  error={errors.goodName && touched.goodName}
                />

                <S.InputSku
                  label={t({
                    id: 'catalogueMarketplace.goodSku'
                  })}
                  type="text"
                  name="goodSku"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodSkuPlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodSku}
                  error={errors.goodSku && touched.goodSku}
                />
                <S.InputPrice
                  label={t({
                    id: 'catalogueMarketplace.goodPrice'
                  })}
                  type="number"
                  name="goodPrice"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodPricePlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodPrice}
                  error={errors.goodPrice && touched.goodPrice}
                />

                <S.InputVat
                  label={t({
                    id: 'catalogueMarketplace.goodVat'
                  })}
                  type="number"
                  name="goodVAT"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodVatPlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodVAT}
                  error={errors.goodVAT && touched.goodVAT}
                />

                <S.SelectUnitType
                  label={t({
                    id: 'catalogueMarketplace.goodUnitType'
                  })}
                  name="goodUnitType"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodUnitTypePlaceholder'
                  })}
                  onChange={handleChange}
                  options={allUnitTypes.marketplaceGoodsUnitTypes.map(
                    (unitType: any) => ({
                      label: unitType.description,
                      value: unitType.id
                    })
                  )}
                  onBlur={handleBlur}
                  value={values.goodUnitType}
                  error={errors.goodUnitType && touched.goodUnitType}
                />

                <S.SelectCategory
                  label={t({
                    id: 'catalogueMarketplace.goodCategory'
                  })}
                  name="goodCategory"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodCategoryPlaceholder'
                  })}
                  onChange={handleChange}
                  options={allCategories.allCategories.map(
                    (categoryOption: I.CategoryOption): I.OptionObj => ({
                      label: categoryOption.description,
                      value: categoryOption.id
                    })
                  )}
                  onBlur={handleBlur}
                  value={values.goodCategory}
                  error={errors.goodCategory && touched.goodCategory}
                />

                <S.SelectSupplier
                  label={t({
                    id: 'catalogueMarketplace.goodSupplier'
                  })}
                  name="goodSupplier"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodSupplierPlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={allSuppliers.marketplaceSuppliersList.entries.map(
                    (supplierOption: I.SupplierOption): I.OptionObj => ({
                      label: supplierOption.supplier.name,
                      value: supplierOption.supplier.id
                    })
                  )}
                  value={values.goodSupplier}
                  error={errors.goodSupplier && touched.goodSupplier}
                />

                <S.InputDescription
                  label={t({
                    id: 'catalogueMarketplace.goodDescription'
                  })}
                  type="goodDescription"
                  name="goodDescription"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodDescriptionPlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodDescription}
                  error={errors.goodDescription && touched.goodDescription}
                />
                <S.InputExternalId
                  label={t({
                    id: 'catalogueMarketplace.goodExternalId'
                  })}
                  type="goodExternalId"
                  name="goodExternalId"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodExternalIdPlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodExternalId}
                  error={errors.goodExternalId && touched.goodExternalId}
                />
              </S.Grid>
              <Stepper
                isValid={isValid}
                stepNumber={stepNumber}
                setStepNumber={setStepNumber}
              />
            </Form>
          )
        }}
      />
    )
  }

  const SignpostForm = () => {
    return (
      <Formik
        initialValues={initialValuesSignpost}
        validate={(values: ValuesSignpost) => addGoodValidationSignpost(values)}
        onSubmit={(values: ValuesSignpost) => submitStep(values)}
        render={({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isValid
        }: FormikProps<ValuesSignpost>) => {
          return (
            <Form>
              <S.Title bold>Main Info</S.Title>
              <S.Grid catalogueType={catalogueType}>
                <S.InputTitle
                  label={t({
                    id: 'catalogueMarketplace.goodName'
                  })}
                  type="text"
                  name="goodName"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodNamePlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodName}
                  error={errors.goodName && touched.goodName}
                />
                <S.InputSku
                  label={t({
                    id: 'catalogueMarketplace.goodSku'
                  })}
                  type="text"
                  name="goodSku"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodSkuPlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodSku}
                  error={errors.goodSku && touched.goodSku}
                />
                <S.InputPrice
                  label={t({
                    id: 'catalogueMarketplace.goodPrice'
                  })}
                  type="number"
                  name="goodPrice"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodPricePlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodPrice}
                  error={errors.goodPrice && touched.goodPrice}
                />
                <S.InputVat
                  label={t({
                    id: 'catalogueMarketplace.goodVat'
                  })}
                  type="number"
                  name="goodVAT"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodVatPlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodVAT}
                  error={errors.goodVAT && touched.goodVAT}
                />
                <S.SelectUnitType
                  label={t({
                    id: 'catalogueMarketplace.goodUnitType'
                  })}
                  name="goodUnitType"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodUnitTypePlaceholder'
                  })}
                  onChange={handleChange}
                  options={allUnitTypes.marketplaceGoodsUnitTypes.map(
                    (unitType: any) => ({
                      label: unitType.description,
                      value: unitType.id
                    })
                  )}
                  onBlur={handleBlur}
                  value={values.goodUnitType}
                  error={errors.goodUnitType && touched.goodUnitType}
                />
                <S.SelectCategory
                  label={t({
                    id: 'catalogueMarketplace.goodCategory'
                  })}
                  name="goodCategory"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodCategoryPlaceholder'
                  })}
                  onChange={handleChange}
                  options={allCategories.allCategories.map(
                    (categoryOption: I.CategoryOption): I.OptionObj => ({
                      label: categoryOption.description,
                      value: categoryOption.id
                    })
                  )}
                  onBlur={handleBlur}
                  value={values.goodCategory}
                  error={errors.goodCategory && touched.goodCategory}
                />
                <S.SelectSource
                  label={t({
                    id: 'catalogueMarketplace.goodSource'
                  })}
                  name="goodSource"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodSourcePlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={allSources.signpostSourcesList.entries.map(
                    (sourceOption: any) => ({
                      label: sourceOption.name,
                      value: sourceOption.id
                    })
                  )}
                  value={values.goodSource}
                  error={errors.goodSource && touched.goodSource}
                />
                <React.Fragment>
                  <S.SelectUrl
                    label={t({
                      id: 'catalogueMarketplace.goodUrl'
                    })}
                    name="goodUrl"
                    placeholder={t({
                      id: 'catalogueMarketplace.goodUrlPlaceholder'
                    })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={allSources.signpostSourcesList.entries.map(
                      (sourceOption: any) => ({
                        label: sourceOption.name,
                        value: sourceOption.id
                      })
                    )}
                    value={values.goodUrl}
                    error={errors.goodUrl && touched.goodUrl}
                  />
                  <S.SelectExternalId
                    label={t({
                      id: 'catalogueMarketplace.goodExternalId'
                    })}
                    name="goodExternalId"
                    placeholder={t({
                      id: 'catalogueMarketplace.goodExternalIdPlaceholder'
                    })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={allSuppliers.marketplaceSuppliersList.entries.map(
                      (supplierOption: I.SupplierOption): I.OptionObj => ({
                        label: supplierOption.supplier.name,
                        value: supplierOption.supplier.id
                      })
                    )}
                    value={values.goodExternalId}
                    error={errors.goodExternalId && touched.goodExternalId}
                  />
                </React.Fragment>

                <S.InputDescription
                  label={t({
                    id: 'catalogueMarketplace.goodDescription'
                  })}
                  type="goodDescription"
                  name="goodDescription"
                  placeholder={t({
                    id: 'catalogueMarketplace.goodDescriptionPlaceholder'
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goodDescription}
                  error={errors.goodDescription && touched.goodDescription}
                />
              </S.Grid>
              <Stepper
                isValid={isValid}
                stepNumber={stepNumber}
                setStepNumber={setStepNumber}
              />
            </Form>
          )
        }}
      />
    )
  }

  return renderForm(catalogueType)
}

export default injectIntl(StepProductInfo)
