import React from 'react'
import Layout from 'components/Shared/Layout/Layout'
import CatalogueMarketplace from 'components/CatalogueMarketplace/CatalogueMarketplace'

const CatalogueView = () => {
  return (
    <Layout>
      <CatalogueMarketplace />
    </Layout>
  )
}

export default CatalogueView
