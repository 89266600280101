export const ROUTES = Object.freeze({
  customers: '/customers',
  catalogueMarketplace: '/catalogue-marketplace',
  catalogueMarketplaceGoodCreate: '/catalogue-marketplace/add',
  catalogueMarketplaceGoodImport: '/catalogue-marketplace/import',
  catalogueSignpost: '/catalogue-signpost',
  catalogueSignpostGoodCreate: '/catalogue-signpost/add',
  catalogueSignpostGoodImport: '/catalogue-signpost/import',
  orderHistory: '/order-history',
  orderHistoryDetail: '/order-history/:id',
  matchingSuggested: '/matching-suggested',
  matchingManual: '/matching-manual',
  matchingMatched: '/matching-matched',
  matching: '/matching',
  sources: '/sources',
  sourcesAdd: '/sources/add',
  sourcesEdit: '/sources/edit/:id',
  suppliers: '/suppliers',
  suppliersAdd: '/suppliers/add',
  suppliersEdit: '/suppliers/:id',
  signIn: '/sign-in'
})
