import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`

export const FormInputs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;

  input:first-of-type {
    margin-right: 2rem;
  }
`

export const FormActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 2rem;

  width: 100%;
`
