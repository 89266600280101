import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import SourcesAdd from '../components/SourcesCRUD/SourcesAdd'

const SourcesAddView = () => {
  return (
    <Layout>
      <SourcesAdd />
    </Layout>
  )
}

export default SourcesAddView
