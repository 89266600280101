import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Text } from 'cimple-web-common'

import { formatAddress } from '../../../utils/formatAddress'

import * as S from '../styled'

type OrderInfoProps = {
  intl: IntlShape
  orderBillingEmail: string
  orderBillingCompany: string
  orderBillingVatNumber: string
  orderDeliveryAddress: {
    street: string
    number: string
    postalCode: string
    city: string
    country: string
  }
  orderBillingAddress: {
    street: string
    number: string
    postalCode: string
    city: string
    country: string
  }
}

const OrderInfo: React.FC<OrderInfoProps> = ({
  intl: { formatMessage: t },
  orderBillingEmail,
  orderBillingCompany,
  orderBillingVatNumber,
  orderDeliveryAddress,
  orderBillingAddress
}) => {
  return (
    <S.OrderInfoWrapper>
      <Text bold>{t({ id: 'orderHistory.orderInfo' })}</Text>
      <S.OrderInfoRow>
        <Text color="greyMedium">{t({ id: 'orderHistory.emailAddress' })}</Text>
        <Text color="greyMedium">{orderBillingEmail}</Text>
      </S.OrderInfoRow>
      <S.OrderInfoRow>
        <Text color="greyMedium">{t({ id: 'orderHistory.companyName' })}</Text>
        <Text color="greyMedium">{orderBillingCompany}</Text>
      </S.OrderInfoRow>
      <S.OrderInfoRow>
        <Text color="greyMedium">{t({ id: 'orderHistory.vatNumber' })}</Text>
        <Text color="greyMedium">{orderBillingVatNumber}</Text>
      </S.OrderInfoRow>
      <S.OrderInfoRow>
        <Text color="greyMedium">
          {t({ id: 'orderHistory.deliveryAddress' })}
        </Text>
        <Text color="greyMedium">{formatAddress(orderDeliveryAddress)}</Text>
      </S.OrderInfoRow>
      <S.OrderInfoRow>
        <Text color="greyMedium">
          {t({ id: 'orderHistory.billingAddress' })}
        </Text>
        <Text color="greyMedium">{formatAddress(orderBillingAddress)}</Text>
      </S.OrderInfoRow>
    </S.OrderInfoWrapper>
  )
}

export default injectIntl(OrderInfo)
