import styled, { css } from 'styled-components'
import * as UI from 'cimple-web-common'

export const Holder = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    `}
`

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled(UI.Text)`
  margin-bottom: 0.3rem;
`
export const UppercaseL = styled(UI.UppercaseL)``

export const Thumbnail = styled(UI.Thumbnail)`
  margin-right: 0.75em;
`

export const ThumbnailSmall = styled(UI.Thumbnail)`
  margin-right: 0.5em;
`

export const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 8rem;
`
