import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useAlert } from 'react-alert'
import { useLocation } from 'react-router-dom'

import {
  SP_IS_IMPORTING,
  MP_IS_IMPORTING
} from '../components/CatalogueGoodImport/query'

export const useWorkingAlerts = () => {
  const alert = useAlert()
  const location = useLocation()
  const { data: signpostData, refetch: refetchSignpost } = useQuery(
    SP_IS_IMPORTING,
    { errorPolicy: 'ignore' }
  )
  const {
    data: marketplaceData,

    refetch: refetchMarketplace
  } = useQuery(MP_IS_IMPORTING, { errorPolicy: 'ignore' })

  React.useEffect(() => {
    refetchSignpost()
    refetchMarketplace()
  }, [location, refetchMarketplace, refetchSignpost])

  const isImportingSignpost =
    signpostData &&
    signpostData.signpostTasksInformation &&
    signpostData.signpostTasksInformation.is_working

  const isImportingMarketplace =
    marketplaceData &&
    marketplaceData.marketplaceTasksInformation &&
    marketplaceData.marketplaceTasksInformation.is_working

  React.useEffect(() => {
    if (isImportingSignpost || isImportingMarketplace) {
      alert.show('Your goods are being uploaded.')
    }
  }, [isImportingSignpost, isImportingMarketplace, alert])
}
