import gql from 'graphql-tag'

export const SIGN_IN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        name
        email
        role
      }
    }
  }
`

export const SIGN_OUT = gql`
  mutation signOut {
    logout {
      user {
        id
      }
    }
  }
`

export const MEMBER_DETAILS = gql`
  query GET_USER {
    me {
      user {
        id
        name
      }
    }
  }
`
