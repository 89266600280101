import styled from 'styled-components'
import { Link as StyledLink } from 'react-router-dom'

export const Holder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
`

export const Link = styled(StyledLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${({ theme }) => theme.colors.brand.default};

  p {
    color: ${({ theme }) => theme.colors.brand.default};
    margin-right: 0.5rem;

    &:hover {
      color: ${({ theme }) => theme.colors.brand.dark};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.brand.dark};
  }
`
