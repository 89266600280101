import React from 'react'
import { Icons } from 'cimple-web-common'

import FileThumbnail from 'components/Shared/File/File'
import { convertFileSize } from 'utils/convertFileSize'

import * as S from '../styled'

type FileProps = {
  index?: number
  file?: File
  removeFile?: (file: File, index: number) => any
  dropzoneServerFiles?: any
}

//TODO: Figure out 0 on place of close (x) problem.

const File: React.FC<FileProps> = ({
  index,
  file,
  removeFile,
  dropzoneServerFiles
}) => {
  return (
    <div>
      {dropzoneServerFiles && (
        <S.FileHolder key={dropzoneServerFiles.small}>
          <S.FileDetails>
            <FileThumbnail fileType="file" />
            <S.FileSpecs>
              <S.FileText>image.jpg</S.FileText>
            </S.FileSpecs>
          </S.FileDetails>
        </S.FileHolder>
      )}
      {file && (
        <S.FileHolder key={file.lastModified}>
          <S.FileDetails>
            <FileThumbnail fileType={file.type} />
            <S.FileSpecs>
              <S.FileText>{file.name}</S.FileText>
              <S.FileSize color="greyMedium">
                {convertFileSize(file.size)}
              </S.FileSize>
            </S.FileSpecs>
          </S.FileDetails>
          {removeFile && index && (
            <S.FileRemove onClick={() => removeFile(file, index)}>
              <Icons.Close />
            </S.FileRemove>
          )}
        </S.FileHolder>
      )}
    </div>
  )
}

export default File
