import React from 'react'
import { Radio, Text } from 'cimple-web-common'

import * as S from './styled'

type MatchingItemProps = {
  goodId: any
  goodImage: any
  goodName: any
  goodPrice: any
  sourceImage: any
  sourceName: any
  unmatchedItem: any
  setUnmatchedItem: any
  disabled: any
}

export const MatchingItem: React.FC<MatchingItemProps> = ({
  goodId,
  goodImage,
  goodName,
  goodPrice,
  sourceImage,
  sourceName,
  unmatchedItem,
  setUnmatchedItem,
  disabled
}) => {
  return (
    <S.Holder key={goodId} disabled={disabled}>
      <S.Flex>
        <S.Thumbnail size="large" src={goodImage} />
        <S.Column>
          <S.Text>{goodName}</S.Text>
          <S.Flex>
            <S.ThumbnailSmall size="mini" src={sourceImage} />
            <S.UppercaseL>{sourceName}</S.UppercaseL>
          </S.Flex>
        </S.Column>
      </S.Flex>
      <S.Action>
        <Text>{goodPrice}</Text>
        <Radio
          checked={unmatchedItem === goodId}
          onChange={() => {
            setUnmatchedItem(goodId)
          }}
        />
      </S.Action>
    </S.Holder>
  )
}

export default MatchingItem
