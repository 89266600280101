import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'

import { Button } from 'cimple-web-common'

import * as S from '../styled'

type PreviewGoodsProps = {
  intl: IntlShape
  importGoodValidateData: any
  handleImportSubmit: any
}

const PreviewGoods: React.FC<PreviewGoodsProps> = ({
  intl: { formatMessage: t },
  importGoodValidateData,
  handleImportSubmit
}) => {
  return (
    <div>
      <S.HeadingM>{t({ id: 'catalogueMarketplace.previewGoods' })}</S.HeadingM>
      <S.Text as="span">
        {`${t({
          id: 'catalogueMarketplace.previewGoodsDescription1'
        })} ${importGoodValidateData.quota} ${t({
          id: 'orderHistory.goods'
        })} ${t({ id: 'catalogueMarketplace.previewGoodsDescription2' })}`}
      </S.Text>{' '}
      <div>
        {importGoodValidateData &&
          importGoodValidateData.previews &&
          importGoodValidateData.previews.map((good: any) => {
            return (
              <S.GoodList
                key={good.name}
                size="large"
                goodName={good.name}
                goodImage={good.image}
                goodSku={good.sku}
              />
            )
          })}
      </div>
      <S.FlexRight>
        <Button onClick={handleImportSubmit}>Start Import</Button>
      </S.FlexRight>
    </div>
  )
}

export default injectIntl(PreviewGoods)
