type Address = {
  street: string
  postalCode: string
  city: string
  country: string
}

export const formatAddress = (adress: Address) => {
  return `${adress.street} ${adress.postalCode} ${adress.city}, ${
    adress.country
  }`
}
