import React from 'react'
import * as S from './styled'

import Loader from 'assets/cimple-loader.gif'

type LoadingProps = {
  small?: boolean
}

const Loading: React.FC<LoadingProps> = ({ small }) => {
  if (small) {
    return (
      <S.Wrap>
        <S.Loader src={Loader} alt="Loading ..." />
      </S.Wrap>
    )
  }

  return (
    <S.LayoutFull>
      <S.Loader src={Loader} alt="Loading ..." />
    </S.LayoutFull>
  )
}

export default Loading
