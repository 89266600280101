import gql from 'graphql-tag'

// All orders

export const ORDER_HISTORY = gql`
  query orderHistory($searchTerm: String, $page: Int!, $pageSize: Int!) {
    results: allOrders(
      searchTerm: $searchTerm
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      totalEntries
      totalPages
      entries {
        id
        status
        insertedAt
        user {
          id
          name
        }
        quota
        suppliers
        total
      }
    }
  }
`

// Specific order

export const ORDER_DETAIL = gql`
  query OrderDetail($id: ID!) {
    results: listOrder(id: $id) {
      id
      status
      billingInfo {
        companyName
        email
        vatNumber
      }
      billingAddress {
        city
        country
        postalCode
        street
      }
      deliveryAddress {
        city
        country
        postalCode
        street
      }
      total
      totalCimpleFeeNet
      totalCimpleFeeVat
      totalDeliveryFeeNet
      totalDeliveryFeeVat
      totalProductsNet
      totalProductsVat
      totalVat
      items {
        imageUrl
        name
        quantity
        sku
        vat
        totalBeforeVatPrice
        totalPrice
        unitPrice
      }
      insertedAt
      updatedAt
    }
  }
`

export const CHANGE_STATUS = gql`
  mutation changeOrderStatus($id: ID!, $status: OrderStatus!) {
    marketplaceOrderUpdate(id: $id, status: $status) {
      id
      status
    }
  }
`
