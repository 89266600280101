import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { injectIntl, IntlShape } from 'react-intl'
import { Table, Text } from 'cimple-web-common'

import {
  LayoutColumn,
  LayoutTables
} from 'components/Shared/Layout/LayoutDetail/LayoutDetails'
import Topbar from 'components/Shared/Navigation/Topbar/Topbar'
import { ArrowLink } from 'components/Shared/Links'
import { Error, Loading } from 'components/Shared/States/index'
import TablePagination from 'components/Shared/Pagination/Pagination'
import TableHeader from './Table/TableHeader'

import { getStatus } from 'utils/getStatus'
import { formatDate } from 'utils/formatDate'
import { handleSearch } from 'utils/handleSearch'

import { ORDER_HISTORY_PAGE_SIZE } from 'constants/pageSize'
import { ERROR_ORDERS } from 'constants/errorTypes'
import { ROUTES } from 'constants/routes'

import { ORDER_HISTORY } from 'modules/OrderHistory/queries'
import { Order } from 'modules/OrderHistory/types'

type OrderHistoryProps = {
  intl: IntlShape
}

const OrderHistory: React.FC<OrderHistoryProps> = ({
  intl: { formatMessage: t }
}) => {
  const history = useHistory()
  const { search } = useLocation()
  const [page, setPage] = React.useState(1)
  const [searchTerm, setSearchTerm] = React.useState(search || '')
  const { data, loading, error } = useQuery(ORDER_HISTORY, {
    variables: {
      searchTerm: searchTerm,
      page: page,
      pageSize: ORDER_HISTORY_PAGE_SIZE
    }
  })

  const handleSearchWithPayload = (values: any) => {
    return handleSearch(history, values, setSearchTerm, ROUTES.orderHistory)
  }

  const orders = data && data.results

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }

  if (orders.entries.length === 0) {
    return (
      <LayoutColumn>
        <Topbar
          pageName={t({ id: 'customers.customer' })}
          totalEntries={orders.totalEntries}
          name="search"
          placeholder={t({ id: 'global.searchById' })}
          handleSearch={handleSearchWithPayload}
        />
        <Error errorType={ERROR_ORDERS} />
      </LayoutColumn>
    )
  }

  return (
    <LayoutColumn>
      <Topbar
        name="search"
        pageName={t({ id: 'orderHistory.orderHistory' })}
        totalEntries={orders.totalEntries}
        placeholder={t({ id: 'global.searchById' })}
        handleSearch={handleSearchWithPayload}
      />

      <LayoutTables>
        <Table>
          <TableHeader />
          <Table.Body>
            {orders.entries.map((order: Order) => {
              return (
                <Table.Row key={order.id}>
                  <Table.Cell>{order.id}</Table.Cell>
                  <Table.Cell>{formatDate(order.insertedAt)}</Table.Cell>
                  <Table.Cell>{getStatus(order.status)}</Table.Cell>
                  <Table.Cell>{order.user.name}</Table.Cell>
                  <Table.Cell>
                    {order.suppliers.map((supplierName: string) => {
                      return <Text key={supplierName}>{supplierName}</Text>
                    })}
                  </Table.Cell>
                  <Table.Cell>
                    {t({ id: 'global.x' })}
                    {order.quota}
                  </Table.Cell>
                  <Table.Cell>{order.total}</Table.Cell>
                  <Table.Cell>
                    <ArrowLink to={`/order-history/${order.id}`}>
                      {t({ id: 'orderHistory.viewDetail' })}
                    </ArrowLink>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

        <TablePagination page={page} setPage={setPage} pageName={orders} />
      </LayoutTables>
    </LayoutColumn>
  )
}

export default injectIntl(OrderHistory)
