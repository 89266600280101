import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import CatalogueSignpost from '../components/CatalogueSignpost/CatalogueSignpost'

const CatalogueSignPostView = () => {
  return (
    <Layout>
      <CatalogueSignpost />
    </Layout>
  )
}

export default CatalogueSignPostView
