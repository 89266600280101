import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Icons, HeadingS, Text } from 'cimple-web-common'

import { getStatus } from 'utils/getStatus'
import { formatDate } from 'utils/formatDate'

import { ALL_ORDER_STATUS } from 'constants/orderStatus'

import { Status } from 'modules/Global/status'

import * as Styled from '../styled'

type OrderTitleProps = {
  intl: IntlShape
  orderDate: string
  orderId: number
  orderStatus: Status
  handleStatusChange: any
}

const OrderTitle: React.FC<OrderTitleProps> = ({
  intl: { formatMessage: t },
  orderDate,
  orderId,
  orderStatus,
  handleStatusChange
}) => {
  const [statusPopover, setStatusPopover] = React.useState(false)

  const filterStatuses = (allStatus: Status[]) => {
    return allStatus.filter((stat: string) => stat !== orderStatus)
  }

  const changeStatus = (status: Status) => {
    setStatusPopover(false)
    handleStatusChange(status)
  }

  return (
    <Styled.OrderTitleWrapper>
      <Styled.BackLink to="/">Back</Styled.BackLink>
      <Styled.OrderTitleMain>
        <Styled.OrderTitle>
          <HeadingS>
            {t({ id: 'orderHistory.order' })}
            {t({ id: 'global.#' })}
            {orderId}
          </HeadingS>
          <Styled.OrderTitleTag status={orderStatus.toLowerCase()}>
            {orderStatus}
          </Styled.OrderTitleTag>
        </Styled.OrderTitle>

        <div>
          <Styled.StatusSelector
            onClick={() => setStatusPopover(!statusPopover)}
          >
            <Text>{getStatus(orderStatus)}</Text>
            {statusPopover ? <Icons.CaretUp /> : <Icons.CaretDown />}
          </Styled.StatusSelector>
          {statusPopover && (
            <Styled.StatusPopover>
              {filterStatuses(ALL_ORDER_STATUS).map((status: Status) => {
                return (
                  <Styled.StatusOption
                    key={status}
                    onClick={() => changeStatus(status)}
                  >
                    {getStatus(status)}
                  </Styled.StatusOption>
                )
              })}
            </Styled.StatusPopover>
          )}
        </div>
      </Styled.OrderTitleMain>

      <Text color="greyMedium">
        {t({ id: 'orderHistory.orderedOn' })} {formatDate(orderDate)}
      </Text>
    </Styled.OrderTitleWrapper>
  )
}

export default injectIntl(OrderTitle)
