import styled from 'styled-components'
import * as UI from 'cimple-web-common'

export const LayoutTopbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.greyscale.white};

  padding: 0.47rem 2rem;
  width: calc(100% - 16rem);

  border: 1px solid ${({ theme }) => theme.colors.greyscale.lighter};
`

export const TopbarInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const TopbarActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button:first-of-type {
    margin-right: 1rem;
  }
`

export const HeadingS = styled(UI.HeadingS)`
  margin-right: 0.5rem;
`

export const Searchbar = styled(UI.Searchbar)`
  margin-left: 4rem;
  max-width: 20rem;
`
