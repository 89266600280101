import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import CatalogueGoodImport from './CatalogueGoodImport'

import { SP_GOOD_IMPORT_VALIDATE, SP_GOOD_IMPORT_SUBMIT } from './query'

type CatalogueSignpostGoodImportProps = {
  isOpen: any
  setIsImportGoodsOpen: any
  onClose: any
}

const CatalogueSignpostGoodImport: React.FC<
  CatalogueSignpostGoodImportProps
> = ({ isOpen, setIsImportGoodsOpen, onClose }) => {
  const [signpostGoodsImportValidate] = useMutation(SP_GOOD_IMPORT_VALIDATE)
  const [signpostGoodsImport] = useMutation(SP_GOOD_IMPORT_SUBMIT)

  const validateSignpostImport = (
    file: any,
    values: any,
    setStepNumber: any,
    setImportGoodValidateErrors: any,
    setImportGoodValidateData: any
  ) => {
    signpostGoodsImportValidate({
      variables: {
        file: file,
        numberOfPreviews: 5,
        sourceId: values.source
      }
    }).then(({ data, errors }) => {
      if (errors) {
        setStepNumber(1)
        setImportGoodValidateErrors(errors)
      }
      if (data && !errors) {
        setStepNumber(2)
        setImportGoodValidateData(data.signpostGoodsImportValidate)
      }
    })
  }

  const submitSignpostImport = (validatedValues: any) => {
    signpostGoodsImport({
      variables: {
        file: validatedValues.file,
        numberOfPreviews: 5,
        sourceId: validatedValues.values.source
      }
    }).then(({ data, errors }) => {
      if (data && !errors) {
        return setIsImportGoodsOpen()
      }
    })
  }

  return (
    <CatalogueGoodImport
      isOpen={isOpen}
      onClose={onClose}
      catalogueType="signpost"
      validateSignpostImport={validateSignpostImport}
      submitSignpostImport={submitSignpostImport}
    />
  )
}

export default CatalogueSignpostGoodImport
