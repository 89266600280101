import styled from 'styled-components'
import * as UI from 'cimple-web-common'

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`

export const EmptyImage = styled.img`
  width: 6rem;

  margin-bottom: 0.5rem;
`

export const Text = styled(UI.Text)`
  max-width: 14rem;
  margin-top: 1rem;
  text-align: center;
`
