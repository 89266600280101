import styled from 'styled-components'
import { Link } from 'react-router-dom'

import * as UI from 'cimple-web-common'

export const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.colors.greyscale.dark};

  display: flex;
  flex-direction: column;
`

export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid ${({ theme }) => theme.colors.greyscale.darkLight};
  padding: 1rem 2rem;
`

export const SidebarItems = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
  padding: 2rem;
`

export const User = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
  padding: 2rem;

  color: ${({ theme }) => theme.colors.greyscale.light};
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;

  color: ${({ theme }) => theme.colors.greyscale.light};
`

export const UserItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-left: 1rem;

  user-select: none;
`

export const ItemsMarketplace = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  a {
    margin-bottom: 1rem;
  }
`

export const ItemsSignpost = styled.div`
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 1rem;
  }
`

export const ItemsName = styled(UI.UppercaseL)`
  margin-bottom: 0.8rem;
`

export const Matching = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 12rem;

  color: ${({ theme }) => theme.colors.greyscale.light};
`

export const MatchingItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;

  user-select: none;

  a {
    margin-bottom: 1rem;
  }
`

export const Logo = styled(UI.Logo)`
  margin-right: 1rem;
`

export const Matchinglink = styled(Link)`
  color: ${({ theme }) => theme.colors.greyscale.medium};
`

export const ButtonLink = styled(UI.Text)`
  cursor: pointer;
`
