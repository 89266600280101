import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { injectIntl, IntlShape } from 'react-intl'
import { useQuery } from '@apollo/react-hooks'
import { Table } from 'cimple-web-common'
import {
  LayoutColumn,
  LayoutTables
} from 'components/Shared/Layout/LayoutDetail/LayoutDetails'
import Topbar from 'components/Shared/Navigation/Topbar/Topbar'
import { Error, Loading } from '../Shared/States/index'

import TableHeader from './Table/TableHeader'
import TablePagination from 'components/Shared/Pagination/Pagination'

import { handleSearch } from 'utils/handleSearch'

import { CUSTOMER_PAGE_SIZE } from 'constants/pageSize'
import { ERROR_RESULTS } from 'constants/errorTypes'
import { ROUTES } from 'constants/routes'
import { CUSTOMERS } from 'modules/Customers/queries'

import { Customer } from 'modules/Customers/types'

type CustomersProps = {
  intl: IntlShape
}

const Customers: React.FC<CustomersProps> = ({
  intl: { formatMessage: t }
}) => {
  const history = useHistory()
  const { search } = useLocation()
  const [page, setPage] = React.useState(1)
  const [searchTerm, setSearchTerm] = React.useState(search || '')

  const { data, loading, error } = useQuery(CUSTOMERS, {
    variables: {
      searchTerm: searchTerm,
      page: page,
      pageSize: CUSTOMER_PAGE_SIZE
    }
  })

  const handleSearchWithPayload = (values: any) =>
    handleSearch(history, values, setSearchTerm, ROUTES.customers)

  const customers = data && data.results

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }

  if (customers.entries.length === 0) {
    return (
      <LayoutColumn>
        <Topbar
          pageName={t({ id: 'customers.customers' })}
          totalEntries={customers.totalEntries}
          name="search"
          placeholder={t({ id: 'global.search' })}
          handleSearch={handleSearchWithPayload}
        />
        <Error errorType={ERROR_RESULTS} />
      </LayoutColumn>
    )
  }

  return (
    <LayoutColumn>
      <Topbar
        pageName={t({ id: 'customers.customers' })}
        totalEntries={customers.totalEntries}
        name="search"
        placeholder={t({ id: 'global.search' })}
        handleSearch={handleSearchWithPayload}
      />
      <LayoutTables>
        <Table>
          <TableHeader />
          <Table.Body>
            {customers.entries.map((customer: Customer) => {
              return (
                <Table.Row key={customer.user.id}>
                  <Table.Cell>{customer.user.name}</Table.Cell>
                  <Table.Cell>{customer.user.email}</Table.Cell>
                  <Table.Cell>{customer.totalOrders}</Table.Cell>
                  <Table.Cell>{customer.totalSpent}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        <TablePagination page={page} setPage={setPage} pageName={customers} />
      </LayoutTables>
    </LayoutColumn>
  )
}

export default injectIntl(Customers)
