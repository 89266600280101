import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import CatalogueSignpostGoodCreate from '../components/CatalogueGoodCreate/CatalogueSignpostGoodCreate'

const CatalogueSignpostGoodCreateView = () => {
  return (
    <Layout>
      <CatalogueSignpostGoodCreate />
    </Layout>
  )
}

export default CatalogueSignpostGoodCreateView
