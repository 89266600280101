import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import MatchingHeader from './MatchingHeader'
import MatchedItem from '../MatchedItem/MatchedItem'
import { Loading, Empty } from '../../Shared/States/index'

import { HIGH_SIMILARITY } from '../../../constants/similarity'
import { MANUAL_MATCH_SUGGESTED } from '../query'
import { toInteger } from '../../../utils/toInteger'

import * as S from '../styled'

const renderContent = (
  suggested: any,
  loading: any,
  error: any,
  matchedItems: any,
  handleMatchedItems: any
) => {
  const withHighSimilarity =
    suggested &&
    suggested.entries.filter((x: any) => x.similarity * 100 >= HIGH_SIMILARITY)

  const withOtherSimilarity =
    suggested &&
    suggested.entries.filter((x: any) => x.similarity * 100 < HIGH_SIMILARITY)

  if (loading) {
    return <Loading small />
  }

  if (error) {
    return <Empty />
  }

  if (suggested && suggested.entries > 0) {
    return <Empty />
  }

  if (withHighSimilarity.length > 0) {
    return (
      <React.Fragment>
        <S.UppercaseL color="greyMedium">
          Highest matching probability
        </S.UppercaseL>
        {withHighSimilarity.map((match: any) => {
          return (
            <MatchedItem
              key={match.id}
              goodId={match.id}
              goodImage={match.images.map((image: any) => image.large)}
              goodName={match.name}
              offers={match.offers}
              similarityPercentage={toInteger(match.similarity)}
              matchedItems={matchedItems}
              handleMatchedItems={handleMatchedItems}
            />
          )
        })}
        <S.UppercaseL color="greyMedium">Other matches</S.UppercaseL>
        {withOtherSimilarity.map((match: any) => {
          return (
            <MatchedItem
              key={match.id}
              goodId={match.id}
              goodImage={match.images.map((image: any) => image.large)}
              goodName={match.name}
              offers={match.offers}
              similarityPercentage={toInteger(match.similarity)}
              matchedItems={matchedItems}
              handleMatchedItems={handleMatchedItems}
            />
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {suggested.entries.map((match: any) => {
        return (
          <MatchedItem
            key={match.id}
            goodId={match.id}
            goodImage={match.images.map((image: any) => image.large)}
            goodName={match.name}
            offers={match.offers}
            similarityPercentage={toInteger(match.similarity)}
            matchedItems={matchedItems}
            handleMatchedItems={handleMatchedItems}
          />
        )
      })}
    </React.Fragment>
  )
}

type MatchingSuggestedProps = {
  itemTomatch: any
  matchedItems: any
  handleMatchedItems: any
}

const MatchingSuggested: React.FC<MatchingSuggestedProps> = ({
  itemTomatch,
  matchedItems,
  handleMatchedItems
}) => {
  const [searchTerm, setSearchTerm] = React.useState(null)
  const { data, loading, error } = useQuery(MANUAL_MATCH_SUGGESTED, {
    variables: {
      goodId: itemTomatch,
      page: 1,
      pageSize: 100,
      searchTerm: searchTerm
    }
  })

  const handleSearch = (values: any) => {
    setSearchTerm(values.search || null)
  }

  const suggested = data && data.results

  return (
    <S.ManualMatchmaker>
      <MatchingHeader
        itemTomatch={itemTomatch}
        matchedItems={matchedItems}
        handleSearch={handleSearch}
      />
      <S.MatchmakerSuggestions>
        {renderContent(
          suggested,
          loading,
          error,
          matchedItems,
          handleMatchedItems
        )}
      </S.MatchmakerSuggestions>
    </S.ManualMatchmaker>
  )
}

export default MatchingSuggested
