import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import SuppliersEdit from '../components/SuppliersCRUD/SuppliersEdit'

const SuppliersEditView = () => {
  return (
    <Layout>
      <SuppliersEdit />
    </Layout>
  )
}

export default SuppliersEditView
