import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { useDropzone } from 'react-dropzone'

import File from './File/File'

import * as S from './styled'

type Files = Array<File>

type DropzoneProps = {
  intl: IntlShape
  singleFile?: boolean
  dropzoneLabel: string
  dropzoneServerFiles?: Array<string>
  dropzoneLocalFiles: Array<File>
  setDropzoneLocalFiles: (files: any) => any
}

const Dropzone: React.FC<DropzoneProps> = ({
  intl: { formatMessage: t },
  singleFile,
  dropzoneLabel,
  dropzoneServerFiles,
  dropzoneLocalFiles,
  setDropzoneLocalFiles
}) => {
  const onDrop = React.useCallback(
    (files: Array<File>) => {
      // For single file submission
      if (singleFile) {
        if (files.length > 1) {
          setDropzoneLocalFiles([files[0]])
        } else {
          setDropzoneLocalFiles(files)
        }
      }

      // For multiple-file dropzones
      if (!singleFile) {
        files.forEach((file: File) => {
          setDropzoneLocalFiles((prevState: Files | []) => [...prevState, file])
        })
      }
    },
    [singleFile, setDropzoneLocalFiles]
  )

  const removeFile = (file: any) => {
    setDropzoneLocalFiles((prevState: any) =>
      prevState.filter((fileObj: any) => {
        return fileObj.name !== file.name
      })
    )
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop })

  const renderServerFiles = () => {
    if (dropzoneServerFiles && dropzoneLocalFiles.length === 0) {
      return (
        <React.Fragment>
          <S.DropzoneLabel color="greyMedium">{dropzoneLabel}</S.DropzoneLabel>
          <File dropzoneServerFiles={dropzoneServerFiles} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <div>
      <S.Holder>{renderServerFiles()}</S.Holder>
      {dropzoneLocalFiles.length > 0 && (
        <S.Holder>
          <S.DropzoneLabel color="greyMedium">{dropzoneLabel}</S.DropzoneLabel>
          {dropzoneLocalFiles.map((file, index: number) => {
            return (
              <File
                key={index}
                index={index}
                file={file}
                removeFile={removeFile}
              />
            )
          })}
        </S.Holder>
      )}

      <S.DropzoneHolder>
        {dropzoneLocalFiles.length === 0 && (
          <S.DropzoneLabel color="greyMedium">{dropzoneLabel}</S.DropzoneLabel>
        )}
        {/* 
        //@ts-ignore */}
        <S.Dropzone {...getRootProps(isDragActive, isDragAccept, isDragReject)}>
          <S.DropzoneInput {...getInputProps()} />
          <S.DropzoneContent>
            <S.Text>{t({ id: 'dropzone.dropDescription' })}</S.Text>
            <S.UppercaseL color="greyMedium">
              {t({ id: 'dropzone.dropSpecs' })}
            </S.UppercaseL>
          </S.DropzoneContent>
        </S.Dropzone>
      </S.DropzoneHolder>
    </div>
  )
}

export default injectIntl(Dropzone)
