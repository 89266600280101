import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import OrderHistoryDetail from '../components/OrderHistoryDetail/OrderHistoryDetail'

const OrderHistoryDetailView = () => {
  return (
    <Layout>
      <OrderHistoryDetail />
    </Layout>
  )
}

export default OrderHistoryDetailView
