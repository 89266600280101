import styled from 'styled-components'

export const LayoutFull = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
`

export const Error = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`

export const ErrorImage = styled.img`
  width: 10rem;

  margin-bottom: 0.5rem;
`
