import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Formik, Form } from 'formik'

import Dropzone from 'components/Shared/Dropzone/Dropzone'
import Stepper from './Stepper'

import * as S from '../styled'

type StepProductImagesProps = {
  intl: IntlShape
  stepNumber: any
  setStepNumber: any
  submitStep: any
}

const StepProductImages: React.FC<StepProductImagesProps> = ({
  intl: { formatMessage: t },
  stepNumber,
  setStepNumber,
  submitStep
}) => {
  const [dropzoneLocalFiles, setDropzoneLocalFiles] = React.useState([])
  const isValid = dropzoneLocalFiles.length > 0

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => submitStep({ images: dropzoneLocalFiles })}
      render={() => {
        return (
          <Form>
            <S.Title>{t({ id: 'catalogueMarketplace.productImages' })}</S.Title>
            <Dropzone
              dropzoneLabel={t({ id: 'catalogueMarketplace.addImage' })}
              dropzoneLocalFiles={dropzoneLocalFiles}
              setDropzoneLocalFiles={setDropzoneLocalFiles}
            />
            <Stepper
              isValid={isValid}
              stepNumber={stepNumber}
              setStepNumber={setStepNumber}
            />
          </Form>
        )
      }}
    />
  )
}

export default injectIntl(StepProductImages)
