import styled, { css } from 'styled-components'

import * as UI from 'cimple-web-common'

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CatalogueItem = styled(Flex)`
  cursor: pointer;
  div {
    margin-right: 0.75rem;
  }
`

export const Options = styled.div<{ isOpen: boolean }>`
  position: relative;
  border-radius: ${({ theme }) => theme.radius.small};
  max-width: 1.5rem;

  &:hover {
    cursor: pointer;

    background-color: ${({ theme }) => theme.colors.greyscale.lighter};
  }

  ${p =>
    p.isOpen &&
    css`
      background-color: ${({ theme }) => theme.colors.greyscale.lighter};
    `}
`

export const OptionsPopover = styled.div`
  background-color: ${({ theme }) => theme.colors.greyscale.white};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.greyscale.light};
  max-width: 8rem;

  padding: 1rem 0.75rem;

  position: absolute;
  top: 2rem;
  left: -2rem;
  z-index: 999;

  -webkit-box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
  box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
`

export const SourceColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const Source = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;

  height: 2.4rem;

  div:first-of-type {
    margin-right: 0.5rem;
  }
`

export const GoodList = styled(UI.GoodList)`
  cursor: pointer;
`
