import React from 'react'
import { Formik, Form, FormikProps } from 'formik'
import { injectIntl, IntlShape } from 'react-intl'

import { Button } from 'cimple-web-common'

import Dropzone from 'components/Shared/Dropzone/Dropzone'

import {
  importGoodMarketplaceValidation,
  importGoodSignpostValidation
} from 'utils/validation/importGoodValidation'

import * as S from '../styled'

import { CatalogueType } from 'modules/Global/catalogueType'

type ImportGoodsProps = {
  catalogueType: CatalogueType
  allSuppliers: any
  allSources: any
  handleImportValidate: any
  intl: IntlShape
}

type SupplierOpt = {
  supplier: { name: string; id: string }
}

type SourceOpt = {
  name: string
  id: string
}

type KeyVal = {
  label: string
  value: string
}

const ImportGoods: React.FC<ImportGoodsProps> = ({
  catalogueType,
  allSuppliers,
  allSources,
  handleImportValidate,
  intl: { formatMessage: t }
}) => {
  const [dropzoneLocalFiles, setDropzoneLocalFiles] = React.useState([])

  const initialValuesMarketplace = {
    supplier: ''
  }

  const initialValuesSignpost = {
    source: ''
  }

  type ValuesMarketplace = typeof initialValuesMarketplace
  type ValuesSignpost = typeof initialValuesSignpost

  const renderForm = (catalogueType: CatalogueType) => {
    const map = {
      marketplace: <MarketplaceForm />,
      signpost: <SignpostForm />
    }

    return map[catalogueType]
  }

  const MarketplaceForm = () => {
    return (
      <Formik
        initialValues={initialValuesMarketplace}
        onSubmit={(values: ValuesMarketplace) =>
          handleImportValidate(values, dropzoneLocalFiles[0])
        }
        validate={(values: ValuesMarketplace) =>
          importGoodMarketplaceValidation(values, catalogueType)
        }
        render={({
          errors,
          handleBlur,
          handleChange,
          touched,
          values
        }: FormikProps<ValuesMarketplace>) => {
          return (
            <Form>
              <S.Select
                label={t({
                  id: 'catalogueMarketplace.goodSupplier'
                })}
                name="supplier"
                placeholder={t({
                  id: 'catalogueMarketplace.goodSupplierPlaceholder'
                })}
                onChange={handleChange}
                onBlur={handleBlur}
                options={allSuppliers.marketplaceSuppliersList.entries.map(
                  (supplierOption: SupplierOpt): KeyVal => ({
                    label: supplierOption.supplier.name,
                    value: supplierOption.supplier.id
                  })
                )}
                value={values.supplier}
                error={errors.supplier && touched.supplier}
              />

              <Dropzone
                singleFile
                dropzoneLabel="dropzone"
                dropzoneLocalFiles={dropzoneLocalFiles}
                setDropzoneLocalFiles={setDropzoneLocalFiles}
              />
              <S.ModalSubmit>
                <Button type="submit">
                  {t({ id: 'catalogueMarketplace.uploadFile' })}
                </Button>
              </S.ModalSubmit>
            </Form>
          )
        }}
      />
    )
  }

  const SignpostForm = () => {
    return (
      <Formik
        initialValues={initialValuesSignpost}
        onSubmit={(values: ValuesSignpost) =>
          handleImportValidate(values, dropzoneLocalFiles[0])
        }
        validate={(values: ValuesSignpost) =>
          importGoodSignpostValidation(values, catalogueType)
        }
        render={({
          errors,
          handleBlur,
          handleChange,
          touched,
          values
        }: FormikProps<ValuesSignpost>) => {
          return (
            <Form>
              <S.Select
                label={t({
                  id: 'catalogueMarketplace.goodSource'
                })}
                name="source"
                placeholder={t({
                  id: 'catalogueMarketplace.goodSourcePlaceholder'
                })}
                onChange={handleChange}
                onBlur={handleBlur}
                options={allSources.signpostSourcesList.entries.map(
                  (sourceOption: SourceOpt): KeyVal => ({
                    label: sourceOption.name,
                    value: sourceOption.id
                  })
                )}
                value={values.source}
                error={errors.source && touched.source}
              />
              <Dropzone
                singleFile
                dropzoneLabel="dropzone"
                dropzoneLocalFiles={dropzoneLocalFiles}
                setDropzoneLocalFiles={setDropzoneLocalFiles}
              />
              <S.ModalSubmit>
                <Button type="submit">
                  {t({ id: 'catalogueMarketplace.uploadFile' })}
                </Button>
              </S.ModalSubmit>
            </Form>
          )
        }}
      />
    )
  }

  return (
    <React.Fragment>
      <S.HeadingM>{t({ id: 'catalogueMarketplace.importGoods' })}</S.HeadingM>
      <S.Text as="span">
        {t({ id: 'catalogueMarketplace.importGoodsDescription' })}
      </S.Text>{' '}
      <S.Text
        as="a"
        href="https://cimple-static-assets.s3-eu-west-1.amazonaws.com/csv_template.csv"
        color="purple"
        underlined
      >
        {t({ id: 'catalogueMarketplace.csvTemplate' })}.
      </S.Text>
      {renderForm(catalogueType)}
    </React.Fragment>
  )
}

export default injectIntl(ImportGoods)
