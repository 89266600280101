import styled from 'styled-components'
import * as UI from 'cimple-web-common'

import StyledFile from '../File/File'

export const Holder = styled.div`
  margin-top: 2rem;
`

export const DropzoneHolder = styled.div`
  margin-top: 2rem;
`

export const DropzoneLabel = styled(UI.Uppercase)`
  margin-bottom: 0.5rem;
`

export const Dropzone = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.greyscale.light};
  border-radius: ${({ theme }) => theme.radius.small};
  position: relative;

  height: 6rem;
  width: 100%;
  display: flex;
`

export const DropzoneContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-self: center;
  align-content: center;
`

export const DropzoneInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
`

export const FileDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FileSpecs = styled.div`
  display: flex;
  flex-direction: column;
`

export const FileHolder = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.brand.default};
  border-radius: ${({ theme }) => theme.radius.small};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 1rem;

  margin-top: 1rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.brand.dark};
    background-color: #f8f7fa;
  }
`

export const FileText = styled(UI.Text)``

export const FileSize = styled(UI.UppercaseL)``

export const FileThumbnail = styled.div<{ src: string }>`
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center center;

  width: 4rem;
  height: 4rem;

  margin-right: 1rem;
`

export const FileRemove = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.greyscale.light};

  &:hover {
    color: ${({ theme }) => theme.colors.brand.default};
  }
`

export const Text = styled(UI.Text)`
  text-align: center;
`
export const UppercaseL = styled(UI.UppercaseL)`
  text-align: center;
`

export const File = styled(StyledFile)`
  margin-right: 1rem;
`
