const flatten = (obj: any, path = '') => {
  if (!(obj instanceof Object)) return { [path.replace(/\.$/g, '')]: obj }

  return Object.keys(obj).reduce(
    (output: any, key: any): any =>
      obj instanceof Array
        ? { ...output, ...flatten(obj[key], `${path}[${key}].`) }
        : { ...output, ...flatten(obj[key], `${path + key}.`) },
    {}
  )
}

export default flatten
