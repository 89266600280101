import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { GoodList, Table, Text } from 'cimple-web-common'

import { OrderItem } from 'modules/OrderHistory/types'
import * as Styled from '../styled'

type OrderGoodsProps = {
  intl: IntlShape
  orderItems: OrderItem[]
}

const OrderGoods: React.FC<OrderGoodsProps> = ({
  intl: { formatMessage: t },
  orderItems
}) => {
  return (
    <React.Fragment>
      <Text bold>{t({ id: 'orderHistory.goods' })}</Text>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orderItems.map((orderItem: OrderItem) => {
            return (
              <Table.Row key={orderItem.sku}>
                <Styled.GoodTableCell>
                  <GoodList
                    goodSku={orderItem.sku}
                    goodName={orderItem.name}
                    goodImage={orderItem.imageUrl}
                  />
                </Styled.GoodTableCell>
                <Styled.GoodTableCell>
                  <Text>{orderItem.quantity}</Text>
                </Styled.GoodTableCell>
                <Styled.GoodTableCell>
                  <Text>{orderItem.unitPrice}</Text>
                </Styled.GoodTableCell>
                <Styled.GoodTableCell>
                  <Text>{orderItem.totalPrice}</Text>
                </Styled.GoodTableCell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

export default injectIntl(OrderGoods)
