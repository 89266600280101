import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Formik, FormikProps } from 'formik'
import { injectIntl, IntlShape } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Button, Input, HeadingM } from 'cimple-web-common'

import {
  LayoutCRUD,
  LayoutModal
} from '../Shared/Layout/LayoutDetail/LayoutDetails'

import { addSupplierValidation } from '../../utils/validation/addSupplierValidation'
import { EDIT_SUPPLIER } from 'modules/Suppliers/queries'

import * as S from './styled'
import * as I from './types'
import { ROUTES } from 'constants/routes'

type SuppliersEditProps = {
  intl: IntlShape
}

const SuppliersEdit: React.FC<SuppliersEditProps> = ({
  intl: { formatMessage: t }
}) => {
  const history = useHistory()

  const [supplierId, setSupplierId] = React.useState('')
  const [editSupplier, { loading }] = useMutation(EDIT_SUPPLIER)

  React.useEffect(() => {
    const { pathname } = history.location

    const hasId = pathname.match(/\d/g)
    if (hasId) {
      setSupplierId(hasId.join(''))
    }
  }, [history, history.location])

  const handleSubmission = (values: I.EditSupplierValues) => {
    const { supplierName, supplierEmail } = values

    editSupplier({
      variables: {
        input: { id: supplierId, name: supplierName, email: supplierEmail }
      }
    })

    history.push({
      pathname: ROUTES.suppliers
    })
  }

  const initialValues = { supplierEmail: '', supplierName: '' }
  type Values = typeof initialValues

  return (
    <LayoutModal>
      <LayoutCRUD>
        <HeadingM>{t({ id: 'suppliers.editSupplier' })}</HeadingM>
        <Formik
          initialValues={initialValues}
          validate={addSupplierValidation}
          onSubmit={(values: Values) => handleSubmission(values)}
          render={({
            errors,
            isValid,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }: FormikProps<Values>) => {
            return (
              <S.Form onSubmit={handleSubmit}>
                <S.FormInputs>
                  <Input
                    label={t({ id: 'suppliers.supplierName' })}
                    type="text"
                    name="supplierName"
                    placeholder={t({ id: 'suppliers.supplierNamePlaceholder' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.supplierName}
                    error={errors.supplierName && touched.supplierName}
                  />
                  <Input
                    label={t({ id: 'suppliers.emailAddress' })}
                    type="email"
                    name="supplierEmail"
                    placeholder={t({ id: 'suppliers.emailAddressPlaceholder' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.supplierEmail}
                    error={errors.supplierEmail && touched.supplierEmail}
                  />
                </S.FormInputs>
                <S.FormActions>
                  <Button type="submit" disabled={!isValid} isLoading={loading}>
                    Confirm
                  </Button>
                </S.FormActions>
              </S.Form>
            )
          }}
        />
      </LayoutCRUD>
    </LayoutModal>
  )
}

export default injectIntl(SuppliersEdit)
