import gql from 'graphql-tag'

export const GOOD_CREATE_MARKETPLACE = gql`
  mutation marketplaceGoodCreate(
    $input: MarketplaceGoodInput!
    $image_1: Upload
    $image_2: Upload
    $image_3: Upload
    $image_4: Upload
    $image_5: Upload
  ) {
    result: marketplaceGoodCreate(
      input: $input
      image_1: $image_1
      image_2: $image_2
      image_3: $image_3
      image_4: $image_4
      image_5: $image_5
    ) {
      id
    }
  }
`

export const GOOD_CREATE_SIGNPOST = gql`
  mutation signpostGoodCreate(
    $input: SignpostGoodInput!
    $image_1: Upload
    $image_2: Upload
    $image_3: Upload
    $image_4: Upload
    $image_5: Upload
  ) {
    result: signpostGoodCreate(
      input: $input
      image_1: $image_1
      image_2: $image_2
      image_3: $image_3
      image_4: $image_4
      image_5: $image_5
    ) {
      id
    }
  }
`

export const ALL_CATEGORIES = gql`
  query allCategories {
    allCategories: marketplaceGoodsCategories {
      description
      id
    }
  }
`

export const ALL_UNIT_TYPES = gql`
  query {
    marketplaceGoodsUnitTypes {
      description
      id
    }
  }
`

// TODO: Change query to not use pagination.

export const ALL_SUPPLIERS = gql`
  query getAllSuppliers {
    marketplaceSuppliersList(pagination: { page: 1, pageSize: 1000000 }) {
      entries {
        supplier {
          id
          name
        }
      }
    }
  }
`

// TODO: Change query to not use pagination.

export const ALL_SOURCES = gql`
  query getAllSuppliers {
    signpostSourcesList(pagination: { page: 1, pageSize: 1000000 }) {
      entries {
        id
        name
      }
    }
  }
`
