import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import SourcesEdit from '../components/SourcesCRUD/SourcesEdit'

const SourcesEditView = () => {
  return (
    <Layout>
      <SourcesEdit />
    </Layout>
  )
}

export default SourcesEditView
