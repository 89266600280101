import React from 'react'
import * as S from './styled'

type LayoutAuth = {}

const LayoutAuth: React.FC<LayoutAuth> = ({ children }) => {
  return (
    <S.Holder>
      <S.Wrapper>{children}</S.Wrapper>
    </S.Holder>
  )
}

export default LayoutAuth
