import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { injectIntl, IntlShape } from 'react-intl'
import { useQuery } from '@apollo/react-hooks'

import { Icons, GoodList, Table } from 'cimple-web-common'
import {
  LayoutColumn,
  LayoutTables
} from 'components/Shared/Layout/LayoutDetail/LayoutDetails'
import Topbar from 'components/Shared/Navigation/Topbar/Topbar'
import { Error, Loading } from 'components/Shared/States/index'
import TablePagination from 'components/Shared/Pagination/Pagination'
import TableHeader from './Table/TableHeader'

import { SOURCES_PAGE_SIZE } from 'constants/pageSize'
import { ERROR_RESULTS } from 'constants/errorTypes'
import { ROUTES } from 'constants/routes'
import { handleSearch } from 'utils/handleSearch'

import { GET_ALL_SOURCES } from 'modules/Sources/queries'

import * as S from './styled'
import * as I from './types'

type SourcesProps = {
  intl: IntlShape
}

const Sources: React.FC<SourcesProps> = ({ intl: { formatMessage: t } }) => {
  const history = useHistory()
  const { search } = useLocation()
  const [page, setPage] = React.useState(1)
  const [searchTerm, setSearchTerm] = React.useState(search || '')

  const { data, loading, error } = useQuery(GET_ALL_SOURCES, {
    variables: {
      searchTerm: searchTerm,
      page: page,
      pageSize: SOURCES_PAGE_SIZE
    }
  })

  const handleSearchWithPayload = (values: any) =>
    handleSearch(history, values, setSearchTerm, ROUTES.sources)

  const sources = data && data.signpostSourcesList

  const withActions = {
    primary: {
      actionName: 'Add source',
      actionTo: `${ROUTES.sourcesAdd}`
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }

  if (sources.entries.length === 0) {
    return (
      <LayoutColumn>
        <Topbar
          pageName={t({ id: 'sources.sources' })}
          totalEntries={sources.totalEntries}
          name="search"
          placeholder={t({ id: 'global.search' })}
          handleSearch={handleSearchWithPayload}
          withActions={withActions}
        />
        <Error errorType={ERROR_RESULTS} />
      </LayoutColumn>
    )
  }

  return (
    <LayoutColumn>
      <Topbar
        pageName={t({ id: 'sources.sources' })}
        totalEntries={sources.totalEntries}
        name="search"
        placeholder={t({ id: 'global.search' })}
        handleSearch={handleSearchWithPayload}
        withActions={withActions}
      />
      <LayoutTables>
        <Table>
          <TableHeader />
          <Table.Body>
            {sources.entries.map((source: I.Source) => {
              return (
                <Table.Row key={source.id}>
                  <Table.Cell>
                    <GoodList
                      goodImage={source.image.small}
                      goodName={source.name}
                      noSku
                      small
                    />
                  </Table.Cell>
                  <Table.Cell>{source.email || '—'} </Table.Cell>
                  <Table.Cell>{source.website || '—'}</Table.Cell>
                  <Table.Cell>
                    <S.Edit
                      onClick={() =>
                        history.push({ pathname: `/sources/edit/${source.id}` })
                      }
                    >
                      <Icons.Ellipsis />
                    </S.Edit>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        <TablePagination page={page} setPage={setPage} pageName={sources} />
      </LayoutTables>
    </LayoutColumn>
  )
}

export default injectIntl(Sources)
