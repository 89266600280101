import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { injectIntl, IntlShape } from 'react-intl'
import { Icons, Table } from 'cimple-web-common'
import {
  LayoutColumn,
  LayoutTables
} from '../Shared/Layout/LayoutDetail/LayoutDetails'

import Topbar from 'components/Shared/Navigation/Topbar/Topbar'
import { Error, Loading } from '../Shared/States/index'

import TableHeader from './Table/TableHeader'
import TablePagination from '../Shared/Pagination/Pagination'

import { handleSearch } from 'utils/handleSearch'

import { SUPPLIERS_PAGE_SIZE } from 'constants/pageSize'
import { ERROR_RESULTS } from 'constants/errorTypes'
import { ROUTES } from 'constants/routes'

import { GET_ALL_SUPPLIERS } from 'modules/Suppliers/queries'
import { Supplier } from 'modules/Suppliers/types'

type SuppliersProps = {
  intl: IntlShape
}

const Suppliers: React.FC<SuppliersProps> = ({
  intl: { formatMessage: t }
}) => {
  const history = useHistory()
  const { search } = useLocation()

  const [page, setPage] = React.useState(1)
  const [searchTerm, setSearchTerm] = React.useState(search || '')

  const { data, loading, error } = useQuery(GET_ALL_SUPPLIERS, {
    variables: {
      searchTerm: searchTerm,
      page: page,
      pageSize: SUPPLIERS_PAGE_SIZE
    }
  })

  const handleSearchWithPayload = (values: any) => {
    handleSearch(history, values, setSearchTerm, ROUTES.suppliers)
  }

  const withActions = {
    primary: {
      actionName: 'Add supplier',
      actionTo: `${ROUTES.suppliersAdd}`
    }
  }

  const suppliers = data && data.results

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }

  if (suppliers.entries.length === 0) {
    return (
      <LayoutColumn>
        <Topbar
          pageName={t({ id: 'customers.customer' })}
          totalEntries={suppliers.totalEntries}
          name="search"
          placeholder={t({ id: 'global.search' })}
          handleSearch={handleSearchWithPayload}
          withActions={withActions}
        />
        <Error errorType={ERROR_RESULTS} />
      </LayoutColumn>
    )
  }

  return (
    <LayoutColumn>
      <Topbar
        name="search"
        pageName={t({ id: 'suppliers.suppliers' })}
        totalEntries={suppliers.totalEntries}
        placeholder={t({ id: 'global.search' })}
        handleSearch={handleSearchWithPayload}
        withActions={withActions}
      />
      <LayoutTables>
        <Table>
          <TableHeader />
          <Table.Body>
            {suppliers.entries.map((supplier: Supplier) => {
              return (
                <Table.Row key={supplier.supplier.id}>
                  <Table.Cell>{supplier.supplier.name}</Table.Cell>
                  <Table.Cell>{supplier.supplier.id}</Table.Cell>
                  <Table.Cell>{supplier.supplier.email}</Table.Cell>
                  <Table.Cell>{supplier.totalSales}</Table.Cell>
                  <Table.Cell>{supplier.totalRevenue}</Table.Cell>
                  <Table.Cell>
                    <Icons.Ellipsis
                      onClick={() =>
                        history.push({
                          pathname: `suppliers/${supplier.supplier.id}`
                        })
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

        <TablePagination page={page} setPage={setPage} pageName={suppliers} />
      </LayoutTables>
    </LayoutColumn>
  )
}

export default injectIntl(Suppliers)
