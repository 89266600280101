import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'

import { LayoutColumn } from '../Shared/Layout/LayoutDetail/LayoutDetails'
import Topbar from 'components/Shared/Navigation/Topbar/Topbar'

import * as S from './styled'

// MOCK DATA
const goods = [
  {
    name: 'Good name',
    id: 1,
    source: {
      name: 'Supplier name',
      image: {
        small: null,
        large: null
      }
    },
    price: '$10.00',
    image: {
      small:
        'https://cimple-marketplace-assets.s3.amazonaws.com/images/marketplace/goods/Mono%20Clock-093f3e2c-df19-11e9-98cc-063adcffc714_large.jpg?v=63736582623',
      large:
        'https://cimple-marketplace-assets.s3.amazonaws.com/images/marketplace/goods/Mono%20Clock-093f3e2c-df19-11e9-98cc-063adcffc714_large.jpg?v=63736582623'
    }
  },
  {
    name: 'Good name',
    id: 2,
    source: {
      name: 'Supplier name',
      image: {
        small: null,
        large: null
      }
    },
    price: '$10.00',
    image: {
      small:
        'https://cimple-marketplace-assets.s3.amazonaws.com/images/marketplace/goods/Mono%20Clock-093f3e2c-df19-11e9-98cc-063adcffc714_large.jpg?v=63736582623',
      large:
        'https://cimple-marketplace-assets.s3.amazonaws.com/images/marketplace/goods/Mono%20Clock-093f3e2c-df19-11e9-98cc-063adcffc714_large.jpg?v=63736582623'
    }
  }
]

const similarityPercentage = 54
const isMatched = 'positive'
const object = [{}, {}, {}, {}, {}]

type MatchingSuggestedProps = {
  intl: IntlShape
}

const MatchingSuggested: React.FC<MatchingSuggestedProps> = ({
  intl: { formatMessage: t }
}) => {
  return (
    <LayoutColumn>
      <Topbar
        pageName={t({ id: 'matching.suggestedMatching' })}
        name="search"
        totalEntries={0}
        placeholder={t({ id: 'global.search' })}
      />
      <S.Holder>
        {object.map((_, i) => {
          return (
            <S.MatchingPairs
              key={i}
              goods={goods}
              similarityPercentage={similarityPercentage}
              isMatched={isMatched}
            />
          )
        })}
      </S.Holder>
    </LayoutColumn>
  )
}

export default injectIntl(MatchingSuggested)
