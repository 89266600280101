import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import CatalogueGoodCreate from './CatalogueGoodCreate'

import { GOOD_CREATE_SIGNPOST } from 'modules/Catalogue/GoodCreate/queries'
import { ROUTES } from 'constants/routes'

export type KeyValue = {
  key: string
  value: string
}

type DataSignpost = {
  goodName: string
  goodSku: string
  goodPrice: string
  goodVAT: string
  goodCategory: string
  goodSource: string
  goodUrl: string
  goodExternalId: string
  goodUnitType: string
  goodDescription: string
  specifications: KeyValue
}

const CatalogueSignpostGoodCreate = () => {
  const history = useHistory()
  const [signpostGoodCreate] = useMutation(GOOD_CREATE_SIGNPOST)

  const submitSignpostGoodCreate = (
    removeImage: Function,
    createObjectWithImageKeys: () => { number: File },
    goodData: DataSignpost
  ) => {
    const {
      goodName,
      goodSku,
      goodPrice,
      goodVAT,
      goodCategory,
      goodSource,
      goodUrl,
      goodExternalId,
      goodUnitType,
      goodDescription,
      specifications
    } = goodData

    const info = {
      category: goodCategory,
      description: goodDescription,
      name: goodName,
      sku: goodSku,
      specifications: specifications,
      offers: {
        externalId: goodExternalId,
        price: goodPrice,
        url: goodUrl,
        vat: goodVAT,
        sourceId: goodSource
      },
      unitType: goodUnitType
    }

    const variables = {
      input: removeImage(info),
      ...createObjectWithImageKeys
    }

    signpostGoodCreate({
      variables: variables
    }).then(({ data }) => {
      if (data && data.result) {
        history.push({ pathname: ROUTES.catalogueSignpost })
      }
    })
  }

  return (
    <CatalogueGoodCreate
      catalogueType="signpost"
      submitSignpostGoodCreate={submitSignpostGoodCreate}
    />
  )
}

export default CatalogueSignpostGoodCreate
