import React from 'react'
import { Text } from 'cimple-web-common'

import * as S from './styled'

type BackLinkProps = {
  to: string
}

const BackLink: React.FC<BackLinkProps> = ({ children, to, ...props }) => {
  return (
    <S.Holder>
      <S.Link to={to} {...props}>
        <S.Arrow />
        <Text>{children}</Text>
      </S.Link>
    </S.Holder>
  )
}

export default BackLink
