import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Table, UppercaseL } from 'cimple-web-common'

type TableHeaderProps = {
  intl: IntlShape
}

const TableHeader: React.FC<TableHeaderProps> = ({
  intl: { formatMessage: t }
}) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.Cell>
          <UppercaseL color="greyMedium">
            {t({ id: 'customers.customer' })}
          </UppercaseL>
        </Table.Cell>
        <Table.Cell>
          <UppercaseL color="greyMedium">
            {t({ id: 'customers.email' })}
          </UppercaseL>
        </Table.Cell>
        <Table.Cell>
          <UppercaseL color="greyMedium">
            {t({ id: 'customers.orders' })}
          </UppercaseL>
        </Table.Cell>
        <Table.Cell>
          <UppercaseL color="greyMedium">
            {t({ id: 'customers.totalSpent' })}
          </UppercaseL>
        </Table.Cell>
      </Table.Row>
    </Table.Header>
  )
}

export default injectIntl(TableHeader)
