import React from 'react'
import { useHistory } from 'react-router-dom'

import { injectIntl } from 'react-intl'
import { useMutation, useQuery } from '@apollo/react-hooks'

import OrderTitle from 'components/OrderHistoryDetail/components/OrderTitle'
import OrderGoods from 'components/OrderHistoryDetail/components/OrderGoods'
import OrderInfo from 'components/OrderHistoryDetail/components/OrderInfo'
import OrderSummary from 'components/OrderHistoryDetail/components/OrderSummary'
import { Error, Loading } from 'components/Shared/States/index'

import { AFTER_LAST_SLASH } from 'utils/regex'

import { CHANGE_STATUS, ORDER_DETAIL } from 'modules/OrderHistory/queries'

import * as S from './styled'
import { Status } from 'utils/getStatus'

type OrderHistoryDetailProps = {}

const OrderHistoryDetail: React.FC<OrderHistoryDetailProps> = () => {
  const history = useHistory()

  const checkForPathname = () => {
    const pathnameUrl = history.location.pathname.match(AFTER_LAST_SLASH)

    if (pathnameUrl && pathnameUrl.length > 0) {
      return pathnameUrl[0]
    }

    return null
  }

  const [id] = React.useState(checkForPathname())

  const [changeOrderStatus] = useMutation(CHANGE_STATUS)
  const { data, error, loading } = useQuery(ORDER_DETAIL, {
    variables: { id: id }
  })

  const handleStatusChange = (status: Status) => {
    changeOrderStatus({
      variables: {
        id: id,
        status: status
      }
    })
  }

  const orderDetail = data && data.results

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }

  return (
    <S.OrderHistoryWrapper>
      <S.OrderHistoryDetail>
        <OrderTitle
          orderId={orderDetail.id}
          orderStatus={orderDetail.status}
          orderDate={orderDetail.insertedAt}
          handleStatusChange={handleStatusChange}
        />
        <OrderGoods orderItems={orderDetail.items} />
        <OrderInfo
          orderBillingEmail={orderDetail.billingInfo.email}
          orderBillingCompany={orderDetail.billingInfo.companyName}
          orderBillingVatNumber={orderDetail.billingInfo.vatNumber}
          orderDeliveryAddress={orderDetail.deliveryAddress}
          orderBillingAddress={orderDetail.billingAddress}
        />
        <OrderSummary
          total={orderDetail.total}
          totalCimpleFeeNet={orderDetail.totalCimpleFeeNet}
          totalCimpleFeeVat={orderDetail.totalCimpleFeeVat}
          totalDeliveryFeeNet={orderDetail.totalDeliveryFeeNet}
          totalDeliveryFeeVat={orderDetail.totalDeliveryFeeVat}
          totalProductsNet={orderDetail.totalProductsNet}
          totalProductsVat={orderDetail.totalProductsVat}
          totalVat={orderDetail.totalVat}
        />
      </S.OrderHistoryDetail>
    </S.OrderHistoryWrapper>
  )
}

export default injectIntl(OrderHistoryDetail)
