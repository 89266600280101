import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import CatalogueMarketplaceGoodCreate from '../pages/CatalogueMarketplaceGoodCreate'
import CatalogueSignpostGoodCreate from '../pages/CatalogueSignpostGoodCreate'

import OrderHistory from '../pages/OrderHistory'
import OrderHistoryDetail from '../pages/OrderHistoryDetail'
import Customers from '../pages/Customers'
import Suppliers from '../pages/Suppliers'
import SuppliersAdd from '../pages/SuppliersAdd'
import SuppliersEdit from '../pages/SuppliersEdit'
import CatalogueMarketplace from '../pages/CatalogueMarketplace'

import Sources from '../pages/Sources'
import SourcesAdd from '../pages/SourcesAdd'
import SourcesEdit from '../pages/SourcesEdit'
import CatalogueSignpost from '../pages/CatalogueSignpost'
import MatchingSuggested from '../pages/MatchingSuggested'
import MatchingManual from '../pages/MatchingManual'
import MatchingMatched from '../pages/MatchingMatched'

import { ROUTES } from '../constants/routes'

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={ROUTES.orderHistory} />}
      />

      {/* Marketplace Section */}
      <Route exact path={ROUTES.orderHistory} component={OrderHistory} />
      <Route
        exact
        path={ROUTES.orderHistoryDetail}
        component={OrderHistoryDetail}
      />
      <Route exact path={ROUTES.suppliers} component={Suppliers} />
      <Route exact path={ROUTES.suppliersAdd} component={SuppliersAdd} />
      <Route path={ROUTES.suppliersEdit} component={SuppliersEdit} />
      <Route path={ROUTES.customers} component={Customers} />
      <Route
        exact
        path={ROUTES.catalogueMarketplace}
        component={CatalogueMarketplace}
      />
      <Route
        exact
        path={ROUTES.catalogueMarketplaceGoodCreate}
        component={CatalogueMarketplaceGoodCreate}
      />
      <Route
        exact
        path={ROUTES.catalogueMarketplaceGoodImport}
        component={CatalogueMarketplace}
      />

      {/* Signpost Section */}
      <Route exact path={ROUTES.sources} component={Sources} />
      <Route exact path={ROUTES.sourcesAdd} component={SourcesAdd} />
      <Route exact path={ROUTES.sourcesEdit} component={SourcesEdit} />
      <Route
        exact
        path={ROUTES.catalogueSignpost}
        component={CatalogueSignpost}
      />
      <Route
        exact
        path={ROUTES.catalogueSignpostGoodCreate}
        component={CatalogueSignpostGoodCreate}
      />
      <Route
        exact
        path={ROUTES.catalogueSignpostGoodImport}
        component={CatalogueSignpost}
      />

      {/* Matching Section */}
      <Route
        exact
        path={ROUTES.matchingSuggested}
        component={MatchingSuggested}
      />
      <Route exact path={ROUTES.matchingManual} component={MatchingManual} />
      <Route exact path={ROUTES.matchingMatched} component={MatchingMatched} />
    </Switch>
  )
}

export default Routes
