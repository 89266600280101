import styled from 'styled-components'
import * as UI from 'cimple-web-common'
import { BackLink as StyledBackLink } from '../Shared/Links/index'

/* Common */

export const OrderHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  height: 100%;
  width: 100%;
`

export const OrderHistoryDetail = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 30rem;
`

/* Order Title */
export const OrderTitleWrapper = styled.div`
  margin: 2rem 0rem 2rem 0rem;
  width: 100%;
`

export const BackLink = styled(StyledBackLink)`
  margin-bottom: 1rem;
`

export const OrderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 0.5rem;
`

export const OrderTitleMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: relative;

  margin-bottom: 0.5rem;
`

export const OrderTitleTag = styled(UI.Tag)`
  margin-left: 1rem;
`

export const StatusSelector = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  cursor: pointer;
`

export const StatusPopover = styled.div`
  background-color: ${({ theme }) => theme.colors.greyscale.white};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.greyscale.light};
  max-width: 8rem;

  padding: 1rem 0.75rem;

  position: absolute;
  top: 2rem;

  z-index: 999;

  -webkit-box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
  box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
`

export const StatusOption = styled.div`
  display: block;
  margin-bottom: 1rem;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0rem;
  }
`

/* Order Info */
export const OrderInfoWrapper = styled.div`
  margin-top: 2rem;
`

export const OrderInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.greyscale.light};

  width: 100%;
`

/* Order Summary */

export const OrderSummaryWrapper = styled.div`
  margin: 2rem 0rem;
`

export const OrderSummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0rem;

  width: 100%;
`

export const GoodTableCell = styled(UI.Table.Cell)`
  :not(:first-child) {
    padding-left: 1rem;
  }
`

export const TotalVat = styled(OrderSummaryRow)`
  cursor: pointer;
`

export const VatDetailed = styled.div`
  padding-left: 0.5em;
`
