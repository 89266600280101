import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import CatalogueMarketplaceGoodCreate from '../components/CatalogueGoodCreate/CatalogueMarketplaceGoodCreate'

const CatalogueMarketplaceGoodCreateView = () => {
  return (
    <Layout>
      <CatalogueMarketplaceGoodCreate />
    </Layout>
  )
}

export default CatalogueMarketplaceGoodCreateView
