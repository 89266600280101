import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import SuppliersAdd from '../components/SuppliersCRUD/SuppliersAdd'

const SuppliersAddView = () => {
  return (
    <Layout>
      <SuppliersAdd />
    </Layout>
  )
}

export default SuppliersAddView
