import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import Suppliers from '../components/Suppliers/Suppliers'

const SuppliersView = () => {
  return (
    <Layout>
      <Suppliers />
    </Layout>
  )
}

export default SuppliersView
