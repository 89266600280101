import React from 'react'
import Sidebar from '../Navigation/Sidebar/Sidebar'

import * as S from './styled'

type LayoutProps = {}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <S.Layout>
      <Sidebar />
      {children}
    </S.Layout>
  )
}

export default Layout
