import React from 'react'
import { Formik, FormikProps } from 'formik'

import { Text } from 'cimple-web-common'

import * as S from '../styled'

type MatchingHeaderProps = {
  handleSearch: any
  totalEntries?: any
  itemTomatch: any
  matchedItems: any
}

export const MatchingHeader: React.FC<MatchingHeaderProps> = ({
  handleSearch,
  itemTomatch,
  matchedItems
}) => {
  const initialValues = { search: '' }
  type Values = typeof initialValues

  return (
    <S.MatchmakerHeader>
      {itemTomatch && (
        <React.Fragment>
          <S.Flex>
            <Text bold>Select or Search for a good to be matched</Text>
            <Text>{matchedItems.length} selected</Text>
          </S.Flex>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: Values) => handleSearch(values)}
            render={({
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              touched,
              values
            }: FormikProps<Values>) => {
              return (
                <form onSubmit={handleSubmit}>
                  <S.Searchbar
                    name="search"
                    placeholder="Search"
                    type="input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.search}
                    error={errors.search && touched.search}
                  />
                </form>
              )
            }}
          />
        </React.Fragment>
      )}
    </S.MatchmakerHeader>
  )
}

export default MatchingHeader
