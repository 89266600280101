import gql from 'graphql-tag'

export const CATALOGUE = gql`
  query getCatalogue($searchTerm: String, $page: Int!, $pageSize: Int!) {
    results: marketplaceGoodsAll(
      searchTerm: $searchTerm
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      pageNumber
      pageSize
      totalEntries
      totalPages
      entries {
        id
        name
        images {
          small

          large
        }
        description
        category
        categoryDescription
        price
        supplier {
          id
          name
        }
        sku
        specifications {
          key
          value
        }
      }
    }
  }
`

export const DELETE_GOOD = gql`
  mutation deleteGood($id: ID!) {
    marketplaceGoodDelete(id: $id) {
      id
      name
    }
  }
`
