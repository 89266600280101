import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import MatchingMatched from '../components/MatchingMatched/MatchingMatched'

const MatchingMatchedView = () => {
  return (
    <Layout>
      <MatchingMatched />
    </Layout>
  )
}

export default MatchingMatchedView
