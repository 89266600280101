import React from 'react'

import { AFTER_LAST_SLASH } from '../../../utils/regex'

type FileProps = {
  fileType: string
}

const File: React.FC<FileProps> = ({ fileType }) => {
  const getType = () => {
    const type = fileType.match(AFTER_LAST_SLASH)

    if (type) {
      return type.toString()
    }

    return ''
  }

  return (
    <div style={{ marginRight: '0.5rem' }}>
      <svg width="66" height="66" viewBox="0 0 66 66">
        <defs>
          <circle id="file-jpg-a" cx="32" cy="32" r="32" />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
          <mask id="file-jpg-b" fill="#fff">
            <use xlinkHref="#file-jpg-a" />
          </mask>
          <use
            fill="#F8F7FA"
            stroke="#D9D7E0"
            strokeWidth="1.143"
            xlinkHref="#file-jpg-a"
          />
          <g mask="url(#file-jpg-b)">
            <g transform="translate(11 7)">
              <rect width="48" height="48" />
              <path
                fill="#7E6EE6"
                fillRule="nonzero"
                d="M4.76,3.16 L25.9558186,3.16 L38.44,15.6582759 L38.44,44.84 L4.76,44.84 L4.76,3.16 Z M6.44,4.84 L6.44,43.16 L36.76,43.16 L36.76,16.3535995 L25.2593845,4.84 L6.44,4.84 Z"
              />
              <rect width="31" height="16" x="11" y="23" fill="#7E6EE6" />
              <text
                fontFamily="HKGrotesk-Bold, HK Grotesk"
                fontSize="12"
                fontWeight="bold"
                letterSpacing=".96"
              >
                <tspan x="13.738" y="35" fill="#FFF">
                  {getType.length <= 3
                    ? `.${getType}`
                    : `.${getType()[0]}${getType()[1]}..`}
                </tspan>
              </text>
              <polygon
                fill="#7E6EE6"
                fillRule="nonzero"
                points="1.84 0 .16 0 .16 12.84 13 12.84 13 11.16 1.84 11.16"
                transform="translate(24.6 4)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default File
