import React from 'react'
import { Table } from 'cimple-web-common'

import Options from '../../Shared/Options/Options'
import CatalogueGoodDetail from '../../CatalogueGoodDetail/CatalogueGoodDetail'

import * as S from '../styled'

type KeyValue = {
  key: string
  value: string
}

type Image = {
  small: string
  medium: string
  large: string
}

type CatalogueItem = {
  id: string
  name: string
  images: Image[]
  description: string
  category: string
  categoryDescription: string
  price: string
  supplier: { id: string; name: string }
  sku: string
  specifications: KeyValue[]
}

type TableBodyProps = {
  catalogue: {
    pageNumber: number
    pageSize: number
    totalEntries: number
    totalPages: number
    entries: any
  }
  catalogueDetailId: any
  setCatalogueDetailId: any
  showOptions: any
  setShowOptions: any
  withOptions: any
}

const TableBody: React.FC<TableBodyProps> = ({
  catalogue,
  catalogueDetailId,
  setCatalogueDetailId,
  showOptions,
  setShowOptions,
  withOptions
}) => {
  const renderModal = (catalogueItem: any) => {
    return (
      <CatalogueGoodDetail
        isOpen={!!catalogueDetailId}
        onClose={() => setCatalogueDetailId(!!catalogueDetailId)}
        catalogueItem={catalogueItem}
      />
    )
  }

  return (
    <React.Fragment>
      {catalogue.entries.map((catalogueItem: CatalogueItem) => {
        const imageUrl = catalogueItem.images.map((image: Image) => image.small)

        return (
          <React.Fragment key={catalogueItem.id}>
            <Table.Row>
              <Table.Cell>
                <S.GoodList
                  onClick={() => setCatalogueDetailId(catalogueItem.id)}
                  goodImage={imageUrl}
                  goodName={catalogueItem.name}
                  noSku
                  small
                />
              </Table.Cell>
              <Table.Cell>{catalogueItem.categoryDescription}</Table.Cell>
              <Table.Cell>{catalogueItem.supplier.name}</Table.Cell>
              <Table.Cell>{catalogueItem.price}</Table.Cell>
              <Table.Cell>
                <Options
                  handleClick={() => setShowOptions(catalogueItem.id)}
                  isOpen={showOptions === catalogueItem.id}
                  options={withOptions}
                />
              </Table.Cell>
            </Table.Row>
            {catalogueItem.id === catalogueDetailId
              ? renderModal(catalogueItem)
              : null}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

export default TableBody
