import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useAlert } from 'react-alert'
import { injectIntl, IntlShape } from 'react-intl'

import { LayoutColumn } from '../Shared/Layout/LayoutDetail/LayoutDetails'
import Topbar from 'components/Shared/Navigation/Topbar/Topbar'
import { Button } from 'cimple-web-common'

import MatchingUnmatched from './MatchingUnmatched/MatchingUnmatched'
import MatchingSuggested from './MatchingSuggested/MatchingSuggested'

import { MANUAL_MATCH } from './query'

import * as S from './styled'

type MatchingManualProps = {
  intl: IntlShape
}

const MatchingManual: React.FC<MatchingManualProps> = ({
  intl: { formatMessage: t }
}) => {
  const alert = useAlert()
  const [unmatchedItem, setUnmatchedItem] = React.useState('')
  const [matchedItems, setMatchedItems] = React.useState<string[]>([])
  const triggerCleanup = React.useRef(0)
  const [manualMatch] = useMutation(MANUAL_MATCH)

  const handleMatchedItems = (id: string) => {
    const alreadyExists = matchedItems.indexOf(id) >= 0
    if (alreadyExists) {
      setMatchedItems(matchedItems.filter(item => item !== id))
    } else {
      setMatchedItems([...matchedItems, id])
    }
  }

  const handleMatchSubmit = () => {
    manualMatch({
      variables: {
        goodId: unmatchedItem,
        goodsIds: matchedItems
      }
    }).then(data => {
      if (data) {
        setMatchedItems([])
        setUnmatchedItem('')
        triggerCleanup.current = triggerCleanup.current + 1
        alert.show('Your goods have been matched.', {
          type: 'success',
          timeout: 3000
        })
      }
    })
  }

  return (
    <LayoutColumn>
      <Topbar
        name="topbar"
        noSearch
        pageName={t({ id: 'matching.manualMatching' })}
      />
      <S.Holder>
        <MatchingUnmatched
          key={triggerCleanup.current}
          unmatchedItem={unmatchedItem}
          setUnmatchedItem={setUnmatchedItem}
        />
        <MatchingSuggested
          itemTomatch={unmatchedItem}
          matchedItems={matchedItems}
          handleMatchedItems={handleMatchedItems}
        />
      </S.Holder>
      <S.ActionHolder>
        <Button
          disabled={matchedItems.length === 0}
          onClick={() => handleMatchSubmit()}
        >
          Confirm match
        </Button>
      </S.ActionHolder>
    </LayoutColumn>
  )
}

export default injectIntl(MatchingManual)
