import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { createLink } from 'apollo-absinthe-upload-link'

type GraphQLError = {
  message: string
  locations: string
  path: string
}

const checkEnv = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return 'https://api.marketplace.cimple.uk/v1/graphql'
  } else {
    return 'https://staging.api.marketplace.cimple.uk/v1/graphql'
  }
}

const cache = new InMemoryCache()

// Logs all errors to console.
const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: GraphQLError) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    if (networkError) console.log(`[Network error]: ${networkError}`)
  }),
  createLink({
    uri: checkEnv(),
    credentials: 'include',
    crossDomain: 'include'
  })
])

const isBrowser = typeof window !== 'undefined'

export const apolloClient = new ApolloClient({
  cache,
  connectToDevTools: true,
  ssrMode: isBrowser,
  link
})
