import styled from 'styled-components'
import * as UI from 'cimple-web-common'

export const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  padding: 6rem 2rem 2rem 2rem;
`

export const ManualMatchmaker = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.greyscale.lighter};
  border-radius: ${({ theme }) => theme.radius.small};

  height: 76vh;
  padding: 0rem 1rem;

  box-shadow: ${({ theme }) => theme.shadow.subtle};
  -webkit-box-shadow: ${({ theme }) => theme.shadow.subtle};
`

export const MatchmakerHeader = styled.div`
  background-color: white;
  padding: 1rem 0rem 1rem 0rem;
  height: 18%;
  z-index: 99;
`

export const MatchmakerSuggestions = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0rem 0rem 1rem 0rem;
  width: 100%;
  height: 82%;

  overflow: scroll;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Searchbar = styled(UI.Searchbar)`
  width: 100%;
  max-width: none;
  margin-top: 1rem;
`

export const UppercaseL = styled(UI.UppercaseL)`
  margin-top: 1rem;
`

export const ActionHolder = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 0rem 2rem;
`
