import React from 'react'
import { Formik, FormikProps } from 'formik'
import { injectIntl, IntlShape } from 'react-intl'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import { HeadingM, Button } from 'cimple-web-common'
import {
  LayoutCRUD,
  LayoutModal
} from 'components/Shared/Layout/LayoutDetail/LayoutDetails'
import Dropzone from 'components/Shared/Dropzone/Dropzone'
import { Error, Loading } from 'components/Shared/States/index'

import { GET_SOURCE, EDIT_SOURCE } from 'modules/Sources/queries'
import { AFTER_LAST_SLASH } from 'utils/regex'

import * as S from './styled'
import * as I from 'modules/Sources/types'
import { ROUTES } from 'constants/routes'

type SourcesEditProps = {
  intl: IntlShape
}

const SourcesEdit: React.FC<SourcesEditProps> = ({
  intl: { formatMessage: t }
}) => {
  const history = useHistory()

  const checkForPathname = () => {
    const { pathname } = history.location
    const hasLength = pathname.match(AFTER_LAST_SLASH)

    if (hasLength && hasLength.length > 0) {
      return hasLength[0]
    }

    return null
  }

  const [id] = React.useState(checkForPathname())
  const [editSource, { error: editError }] = useMutation(EDIT_SOURCE)
  const [dropzoneLocalFiles, setDropzoneLocalFiles] = React.useState([])

  const { data, error, loading } = useQuery(GET_SOURCE, {
    variables: {
      id: id
    }
  })

  const handleSubmission = (values: I.SourcesEditValues) => {
    editSource({
      variables: {
        input: {
          id: id,
          name: values.sourceName,
          email: values.sourceEmail,
          description: values.sourceDescription,
          website: values.sourceWebsite
        },
        image: dropzoneLocalFiles[0]
      }
    }).then((data: any) => {
      if (data && data.signpostSourceCreate) {
        history.push({ pathname: ROUTES.sources })
      }
    })
  }

  const source = data && data.results

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }

  const initialValues = {
    sourceName: source.name,
    sourceEmail: source.email,
    sourceWebsite: source.website,
    sourceDescription: source.description
  }

  type Values = typeof initialValues

  return (
    <LayoutModal>
      <LayoutCRUD>
        <HeadingM>{t({ id: 'sources.editSource' })}</HeadingM>
        <Formik
          initialValues={{
            sourceName: source.name,
            sourceEmail: source.email,
            sourceWebsite: source.website,
            sourceDescription: source.description
          }}
          onSubmit={(values: Values) => handleSubmission(values)}
          render={({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }: FormikProps<Values>) => {
            return (
              <S.Form onSubmit={handleSubmit}>
                <div>
                  <S.FormInputs>
                    <S.Input
                      label={t({ id: 'sources.sourceName' })}
                      type="text"
                      name="sourceName"
                      placeholder={t({
                        id: 'sources.sourceNamePlaceholder'
                      })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sourceName}
                      error={errors.sourceName && touched.sourceName}
                    />
                    <S.Input
                      label={t({ id: 'sources.emailAddress' })}
                      type="email"
                      name="sourceEmail"
                      placeholder={t({
                        id: 'sources.emailPlaceholder'
                      })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sourceEmail}
                      error={errors.sourceEmail && touched.sourceEmail}
                    />
                  </S.FormInputs>

                  <Dropzone
                    singleFile
                    dropzoneLabel={t({ id: 'sources.sourceLogo' })}
                    dropzoneServerFiles={source.image}
                    dropzoneLocalFiles={dropzoneLocalFiles}
                    setDropzoneLocalFiles={setDropzoneLocalFiles}
                  />

                  <S.FormInputs>
                    <S.Input
                      label={t({ id: 'sources.website' })}
                      type="text"
                      name="sourceWebsite"
                      placeholder={t({ id: 'sources.websitePlaceholder' })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sourceWebsite}
                      error={errors.sourceWebsite && touched.sourceWebsite}
                    />
                    <S.Input
                      label={t({ id: 'sources.description' })}
                      type="text"
                      name="sourceDescription"
                      placeholder={t({ id: 'sources.descriptionPlaceholder' })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sourceDescription}
                      error={
                        errors.sourceDescription && touched.sourceDescription
                      }
                    />
                  </S.FormInputs>
                </div>

                {editError &&
                  editError.graphQLErrors.map((err: any) => {
                    return (
                      <S.Error color="red" key={err.message}>
                        {err.message}
                      </S.Error>
                    )
                  })}

                <S.FormActions>
                  <Button isLoading={loading} type="submit">
                    {t({ id: 'sources.confirm' })}
                  </Button>
                </S.FormActions>
              </S.Form>
            )
          }}
        />
      </LayoutCRUD>
    </LayoutModal>
  )
}

export default injectIntl(SourcesEdit)
