import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import * as S from '../CatalogueMarketplace/styled'
import * as I from '../CatalogueMarketplace/types'

import { CatalogueItem } from 'modules/Catalogue/ItemDetail'

type CatalogueMarketplaceGoodDetailProps = {
  intl: IntlShape
  catalogueItem: CatalogueItem
  isOpen: boolean
  onClose: () => void
}

const CatalogueMarketplaceGoodDetail: React.FC<
  CatalogueMarketplaceGoodDetailProps
> = ({ intl: { formatMessage: t }, catalogueItem, isOpen, onClose }) => {
  const NA = t({ id: 'global.n/a' })
  const imageUrl = catalogueItem.images.map(
    (image: { large: string }) => image.large
  )

  return (
    <S.Modal isOpen={isOpen} onClose={onClose}>
      <S.HeadingM>{t({ id: 'catalogueDetail.goodDetail' })}</S.HeadingM>
      <S.Image src={imageUrl} />
      <S.Tag>{catalogueItem.categoryDescription}</S.Tag>
      <S.HeadingS>{catalogueItem.name}</S.HeadingS>
      <S.UppercaseL color="greyMedium">
        {t({ id: 'global.sku' })}
        {catalogueItem.sku || NA}
      </S.UppercaseL>
      <S.HeadingS>{catalogueItem.price}</S.HeadingS>
      <S.Description color="greyMedium">
        {catalogueItem.description}
      </S.Description>
      {catalogueItem.specifications.length > 0 && (
        <>
          <S.Text bold>{t({ id: 'catalogueDetail.specifications' })}</S.Text>
          {catalogueItem.specifications.map((spec: I.Variations) => {
            return (
              <S.ModalSpecifications key={spec.key}>
                <S.UppercaseL color="greyMedium">{spec.key}</S.UppercaseL>
                <S.Text>{spec.value}</S.Text>
              </S.ModalSpecifications>
            )
          })}
        </>
      )}
    </S.Modal>
  )
}

export default injectIntl(CatalogueMarketplaceGoodDetail)
