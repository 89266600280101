/* eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { useQuery } from '@apollo/react-hooks'

import {
  LayoutCRUD,
  LayoutModal
} from 'components/Shared/Layout/LayoutDetail/LayoutDetails'
import StepProductInfo from 'components/CatalogueGoodCreate/GoodCreateSteps/StepProductInfo'
import StepProductImages from 'components/CatalogueGoodCreate/GoodCreateSteps/StepProductImages'
import StepProductCharacteristics from 'components/CatalogueGoodCreate/GoodCreateSteps/StepProductCharacteristics'

import {
  ALL_CATEGORIES,
  ALL_SUPPLIERS,
  ALL_UNIT_TYPES,
  ALL_SOURCES
} from 'modules/Catalogue/GoodCreate/queries'

import * as S from './styled'

import { CatalogueType } from 'modules/Global/catalogueType'
import { GoodData } from 'modules/Catalogue/GoodCreate/types'

type Specifications = {
  key: string
  value: string
}

type CatalogueGoodCreateProps = {
  catalogueType: CatalogueType
  submitMarketplaceGoodCreate?: any
  submitSignpostGoodCreate?: any
  intl: IntlShape
}

const CatalogueGoodCreate: React.FC<CatalogueGoodCreateProps> = ({
  catalogueType,
  submitMarketplaceGoodCreate,
  submitSignpostGoodCreate,
  intl: { formatMessage: t }
}) => {
  const [stepNumber, setStepNumber] = React.useState(0)
  const [goodData, setGoodData] = React.useState<any>({})

  const { data: allCategories } = useQuery(ALL_CATEGORIES)
  const { data: allSuppliers } = useQuery(ALL_SUPPLIERS)
  const { data: allUnitTypes } = useQuery(ALL_UNIT_TYPES)
  const { data: allSources } = useQuery(ALL_SOURCES)

  const submitStep = (data: Specifications) => {
    const newData = { ...goodData, ...data }
    setGoodData(newData)

    if (stepNumber === stepMap.length - 1) {
      const images = newData.images

      const removeImage = (newData: GoodData) => {
        delete newData.images
        return newData
      }

      const createObjectWithImageKeys = images.reduce(
        (acc: any, curr: any, i: number) => {
          // The backend doesn't support multiple file submission.
          // So the `image_x` format is used for each file.
          return { ...acc, [`image_${i}`]: curr }
        },
        {}
      )

      const submit = {
        marketplace: () =>
          submitMarketplaceGoodCreate(
            removeImage,
            createObjectWithImageKeys,
            newData
          ),
        signpost: () =>
          submitSignpostGoodCreate(
            removeImage,
            createObjectWithImageKeys,
            newData
          )
      }

      return submit[catalogueType]()
    } else {
      setStepNumber((stepNumber + 1) % stepMap.length)
    }
  }

  const stepMap = [
    <StepProductInfo
      key="productInfo"
      catalogueType={catalogueType}
      allCategories={allCategories}
      allSuppliers={allSuppliers}
      allUnitTypes={allUnitTypes}
      allSources={allSources}
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
      submitStep={submitStep}
    />,
    <StepProductImages
      key="productImages"
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
      submitStep={submitStep}
    />,
    <StepProductCharacteristics
      key="productCharacteristics"
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
      submitStep={submitStep}
    />
  ]

  const renderStep = (stepNumber: number) => {
    return stepMap[stepNumber] || stepMap[0]
  }

  return (
    <LayoutModal>
      <LayoutCRUD>
        <S.HeadingM>{t({ id: 'catalogueMarketplace.addGood' })}</S.HeadingM>
        {renderStep(stepNumber)}
      </LayoutCRUD>
    </LayoutModal>
  )
}

export default injectIntl(CatalogueGoodCreate)
