import React from 'react'
import LayoutAuth from '../components/Shared/Layout/LayoutAuth/LayoutAuth'
import SignIn from '../components/Authentication/SignIn/SignIn'

const SignView = () => {
  return (
    <LayoutAuth>
      <SignIn />
    </LayoutAuth>
  )
}

export default SignView
