import React from 'react'
import * as S from './styled'

type SidebarLinkProps = {
  to: string
  activeClassName: string
}

const SidebarLink: React.FC<SidebarLinkProps> = ({
  activeClassName,
  children,
  to,
  ...props
}) => {
  return (
    <S.Holder>
      <S.NavLink to={to} activeClassName={activeClassName} {...props}>
        <S.Arrow />
        <S.Text color="greyMedium">{children}</S.Text>
      </S.NavLink>
    </S.Holder>
  )
}

export default SidebarLink
