import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Button, Text, UppercaseL } from 'cimple-web-common'

import * as S from '../styled'

type StepperProps = {
  intl: IntlShape
  stepNumber: number
  setStepNumber: (stepNumber: number) => any
  isValid: boolean
}

const Stepper: React.FC<StepperProps> = ({
  intl: { formatMessage: t },
  stepNumber,
  setStepNumber,
  isValid
}) => {
  const isLastStep = 2

  return (
    <S.Stepper>
      {stepNumber > 0 && (
        <Text as="button" onClick={() => setStepNumber(stepNumber - 1)}>
          {t({ id: 'stepper.back' })}
        </Text>
      )}

      <S.Inline>
        <UppercaseL color="purple">{`${t({
          id: 'stepper.step'
        })} ${stepNumber + 1}/`}</UppercaseL>
        <UppercaseL color="greyMedium">{isLastStep + 1}</UppercaseL>
      </S.Inline>

      <Button type="submit" disabled={!isValid}>
        {stepNumber === isLastStep
          ? t({ id: 'stepper.confirm' })
          : t({ id: 'stepper.nextStep' })}
      </Button>
    </S.Stepper>
  )
}

export default injectIntl(Stepper)
