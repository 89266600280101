import React from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Text } from 'cimple-web-common'

import * as Styled from '../styled'

type OrderSummaryProps = {
  intl: IntlShape
  total: string
  totalCimpleFeeNet: string
  totalCimpleFeeVat: string
  totalDeliveryFeeNet: string
  totalDeliveryFeeVat: string
  totalProductsNet: string
  totalProductsVat: string
  totalVat: string
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  intl: { formatMessage: t },
  totalCimpleFeeNet,
  totalCimpleFeeVat,
  totalDeliveryFeeNet,
  totalDeliveryFeeVat,
  totalProductsNet,
  totalProductsVat,
  totalVat,
  total
}) => {
  return (
    <Styled.OrderSummaryWrapper>
      <Text bold>{t({ id: 'orderHistory.orderSummary' })}</Text>
      <Styled.OrderSummaryRow>
        <Text color="greyMedium">
          {t({ id: 'orderHistory.productsTotal' })}
        </Text>
        <Text color="greyMedium">{totalProductsNet}</Text>
      </Styled.OrderSummaryRow>
      <Styled.OrderSummaryRow>
        <Text color="greyMedium">{t({ id: 'orderHistory.cimpleFee' })}</Text>
        <Text color="greyMedium">{totalCimpleFeeNet}</Text>
      </Styled.OrderSummaryRow>
      <Styled.OrderSummaryRow>
        <Text color="greyMedium">{t({ id: 'orderHistory.deliveryFee' })}</Text>
        <Text color="greyMedium">{totalDeliveryFeeNet}</Text>
      </Styled.OrderSummaryRow>
      <Styled.OrderSummaryRow>
        <Text color="greyMedium">{t({ id: 'orderHistory.totalVat' })}</Text>
        <Text color="greyMedium">{totalVat}</Text>
      </Styled.OrderSummaryRow>
      <Styled.VatDetailed>
        <Styled.OrderSummaryRow>
          <Text color="greyMedium">
            - {t({ id: 'orderHistory.productsVat' })}
          </Text>
          <Text color="greyMedium">{totalProductsVat}</Text>
        </Styled.OrderSummaryRow>
        <Styled.OrderSummaryRow>
          <Text color="greyMedium">
            - {t({ id: 'orderHistory.cimpleFeeVat' })}
          </Text>
          <Text color="greyMedium">{totalCimpleFeeVat}</Text>
        </Styled.OrderSummaryRow>
        <Styled.OrderSummaryRow>
          <Text color="greyMedium">
            - {t({ id: 'orderHistory.deliveryFeeVat' })}
          </Text>
          <Text color="greyMedium">{totalDeliveryFeeVat}</Text>
        </Styled.OrderSummaryRow>
      </Styled.VatDetailed>
      <Styled.OrderSummaryRow>
        <Text bold>{t({ id: 'orderHistory.grandTotal' })}</Text>
        <Text color="greyMedium">{total}</Text>
      </Styled.OrderSummaryRow>
    </Styled.OrderSummaryWrapper>
  )
}

export default injectIntl(OrderSummary)
