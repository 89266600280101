import React from 'react'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { Formik, FormikProps } from 'formik'
import { injectIntl, IntlShape } from 'react-intl'

import { signInValidation } from 'utils/validation/signInValidation'
import { SIGN_IN } from 'modules/Authentication/queries'

import * as S from './styled'

type Values = {
  email: string
  password: string
}

type SignInProps = {
  intl: IntlShape
}

const SignIn: React.FC<SignInProps> = ({ intl: { formatMessage: t } }) => {
  const client = useApolloClient()
  const [login, { error, loading }] = useMutation(SIGN_IN)

  const handleSubmission = (values: Values) => {
    const { email, password } = values

    login({
      variables: { email, password }
    }).then(() => client.resetStore())
  }

  const initialValues = { email: '', password: '' }
  type Values = typeof initialValues

  return (
    <div>
      <S.Text color="greyMedium">Welcome back!</S.Text>
      <S.HeadingM>Let&apos;s sign in.</S.HeadingM>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: Values) => {
          handleSubmission(values)
        }}
        validate={signInValidation}
        render={({
          errors,
          isValid,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }: FormikProps<Values>) => {
          return (
            <form onSubmit={handleSubmit}>
              <S.Input
                label={t({ id: 'auth.emailAddress' })}
                type="email"
                name="email"
                placeholder={t({ id: 'auth.sampleEmail' })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={errors.email && touched.email}
              />

              <S.Input
                label={t({ id: 'auth.password' })}
                type="password"
                name="password"
                placeholder={t({ id: 'auth.passwordPlaceholder' })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password}
              />

              {error &&
                error.graphQLErrors.map((err: Error) => (
                  <S.Error color="red" key={err.name}>
                    {err.message}
                  </S.Error>
                ))}

              <S.Button
                type="submit"
                full
                disabled={!isValid}
                isLoading={loading}
              >
                {t({ id: 'auth.signIn' })}
              </S.Button>
            </form>
          )
        }}
      />
    </div>
  )
}

export default injectIntl(SignIn)
