import styled from 'styled-components'
import * as UI from 'cimple-web-common'

export const Input = styled(UI.Input)``

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`

export const FormInputs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;

  ${Input}:first-of-type {
    margin-right: 1rem;
  }
`

export const FormActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 2rem;

  width: 100%;
`

export const Text = styled(UI.Text)`
  text-align: center;
`
export const UppercaseL = styled(UI.UppercaseL)`
  text-align: center;
`

export const Error = styled(UI.Text)`
  margin-bottom: 0.25rem;
`
