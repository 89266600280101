import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import { Button, SecondaryButton, Tag } from 'cimple-web-common'

import * as S from './styled'

type TopbarProps = {
  handleSearch?: (values: any) => void
  name: string
  noSearch?: boolean
  pageName: string
  placeholder?: string
  totalEntries?: number
  withActions?: {
    primary: {
      actionName: string
      actionTo: string
    }
    secondary?: {
      actionName: string
      actionTo: string
    }
  }
}

const Topbar: React.FC<TopbarProps> = ({
  name,
  pageName,
  placeholder,
  totalEntries,
  handleSearch,
  withActions,
  noSearch
}) => {
  const history = useHistory()

  const initialValues = { search: '' }
  type Values = typeof initialValues

  const secondaryAction =
    withActions && withActions.secondary && withActions.secondary.actionTo

  return (
    <S.LayoutTopbar>
      <S.TopbarInfo>
        <S.HeadingS>{pageName}</S.HeadingS>
        {typeof totalEntries === 'number' && <Tag>{totalEntries}</Tag>}
        {!noSearch && (
          <Formik
            initialValues={initialValues}
            onSubmit={(values: Values) => handleSearch && handleSearch(values)}
            render={({
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              touched,
              values
            }: FormikProps<Values>) => {
              return (
                <form onSubmit={handleSubmit}>
                  <S.Searchbar
                    name={name}
                    placeholder={placeholder}
                    type="input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.search}
                    error={errors.search && touched.search}
                  />
                </form>
              )
            }}
          />
        )}
      </S.TopbarInfo>

      {withActions && (
        <S.TopbarActions>
          {withActions.secondary && (
            <SecondaryButton
              onClick={() => history.push({ pathname: secondaryAction })}
            >
              {withActions.secondary.actionName}
            </SecondaryButton>
          )}
          <Button
            onClick={() =>
              history.push({ pathname: withActions.primary.actionTo })
            }
          >
            {withActions.primary.actionName}
          </Button>
        </S.TopbarActions>
      )}
    </S.LayoutTopbar>
  )
}

export default Topbar
