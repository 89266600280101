import gql from 'graphql-tag'

export const GET_ALL_SUPPLIERS = gql`
  query suppliers($searchTerm: String, $page: Int!, $pageSize: Int!) {
    results: marketplaceSuppliersList(
      searchTerm: $searchTerm
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      pageNumber
      pageSize
      totalEntries
      totalPages
      entries {
        supplier {
          id
          name
          email
        }
        totalRevenue
        totalSales
      }
    }
  }
`

export const ADD_SUPPLIER = gql`
  mutation addSupplier($input: MarketplaceSupplierInput) {
    marketplaceSupplierCreate(input: $input) {
      id
    }
  }
`

export const EDIT_SUPPLIER = gql`
  mutation editSupplier($input: MarketplaceSupplierInput) {
    marketplaceSupplierEdit(input: $input) {
      id
    }
  }
`

export const GET_SUPPLIER = gql`
  query supplier($id: ID!) {
    marketplaceSupplier(id: $id) {
      id
      name
      email
    }
  }
`
