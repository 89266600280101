import React from 'react'
import { IntlProvider } from 'react-intl'

import en from './en.json'
import flatten from 'utils/flatten'

import DEFAULT_LOCALE from 'constants/defaultLocale'

type IntlProps = {
  locale: string
  messages: any
}

type ProviderProps = {}

const localeData = { en: flatten(en) }

const getIntlProps = (): IntlProps => {
  return {
    locale: DEFAULT_LOCALE,
    messages: localeData[DEFAULT_LOCALE]
  }
}

const Provider: React.FC<ProviderProps> = ({ children }) => {
  return <IntlProvider {...getIntlProps()}>{children}</IntlProvider>
}

export default Provider
