export const handleSearch = (
  history: any,
  values: any,
  setSearchTerm: any,
  route: string
) => {
  setSearchTerm(values.search)

  history.push({
    pathname: route,
    search: values.search
  })
}
