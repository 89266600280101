import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks'
import { injectIntl, IntlShape } from 'react-intl'
import { Icons, Tag, Text } from 'cimple-web-common'

import { SidebarLink } from 'components/Shared/Links/'

import { ROUTES } from 'constants/routes'
import { MEMBER_DETAILS, SIGN_OUT } from 'modules/Authentication/queries'

import * as S from './styled'

type SidebarProps = {
  intl: IntlShape
}

const Sidebar: React.FC<SidebarProps> = ({ intl: { formatMessage: t } }) => {
  const client = useApolloClient()
  const { pathname } = useLocation()

  const [isMatchingOpen, setIsMatchingOpen] = React.useState(false)
  const [isUserOpen, setIsUserOpen] = React.useState(false)
  const [signOut] = useMutation(SIGN_OUT)
  const { data } = useQuery(MEMBER_DETAILS)

  const user = data && data.me && data.me.user

  React.useEffect(() => {
    if (pathname.match(/(matching)/g)) {
      setIsMatchingOpen(true)
    }
  }, [pathname])

  const handleSignOut = () => {
    signOut({
      variables: {
        id: user.id
      }
    }).then(data => {
      if (data) {
        client.resetStore()
      }
    })
  }

  return (
    <S.Sidebar>
      <S.SidebarHeader>
        <Link to="/">
          <S.Logo white />
        </Link>
        <Tag brand>{t({ id: 'global.admin' })}</Tag>
      </S.SidebarHeader>
      <S.SidebarItems>
        <S.ItemsMarketplace>
          <S.ItemsName color="greyMedium">
            {t({ id: 'navigation.sidebar.marketplace' })}
          </S.ItemsName>
          <SidebarLink to={ROUTES.orderHistory} activeClassName="active">
            {t({ id: 'navigation.sidebar.orderHistory' })}
          </SidebarLink>
          <SidebarLink to={ROUTES.customers} activeClassName="active">
            {t({ id: 'navigation.sidebar.customers' })}
          </SidebarLink>
          <SidebarLink to={ROUTES.suppliers} activeClassName="active">
            {t({ id: 'navigation.sidebar.suppliers' })}
          </SidebarLink>
          <SidebarLink
            to={ROUTES.catalogueMarketplace}
            activeClassName="active"
          >
            {t({ id: 'navigation.sidebar.catalogue' })}
          </SidebarLink>
        </S.ItemsMarketplace>
        <S.ItemsSignpost>
          <S.ItemsName color="greyMedium" activeClassName="active">
            {t({ id: 'navigation.sidebar.compare' })}
          </S.ItemsName>
          <SidebarLink to={ROUTES.sources} activeClassName="active">
            {t({ id: 'navigation.sidebar.sources' })}
          </SidebarLink>
          <SidebarLink to={ROUTES.catalogueSignpost} activeClassName="active">
            {t({ id: 'navigation.sidebar.catalogue' })}
          </SidebarLink>
          <S.Matching onClick={() => setIsMatchingOpen(!isMatchingOpen)}>
            <Text color="greyMedium">
              {t({ id: 'navigation.sidebar.matching' })}
            </Text>
            {isMatchingOpen ? <Icons.CaretUp /> : <Icons.CaretDown />}
          </S.Matching>
          {isMatchingOpen && (
            <S.MatchingItems>
              {/* <SidebarLink
                to={ROUTES.matchingSuggested}
                activeClassName="active"
              >
                {t({ id: 'navigation.sidebar.suggestedMatching' })}
              </SidebarLink> */}
              <SidebarLink to={ROUTES.matchingManual} activeClassName="active">
                {t({ id: 'navigation.sidebar.manualMatching' })}
              </SidebarLink>
              {/* <SidebarLink to={ROUTES.matchingMatched} activeClassName="active">
                {t({ id: 'navigation.sidebar.matched' })}
              </SidebarLink> */}
            </S.MatchingItems>
          )}
        </S.ItemsSignpost>
      </S.SidebarItems>
      <S.User onClick={() => setIsUserOpen(!isUserOpen)}>
        <S.UserInfo>
          <Text color="greyMedium">
            {user && <span>{user && user.name}</span>}
          </Text>
          {isUserOpen ? <Icons.CaretUp /> : <Icons.CaretDown />}
        </S.UserInfo>
        {isUserOpen && (
          <S.UserItems>
            <S.ButtonLink color="greyMedium" onClick={() => handleSignOut()}>
              Sign out
            </S.ButtonLink>
          </S.UserItems>
        )}
      </S.User>
    </S.Sidebar>
  )
}

export default injectIntl(Sidebar)
