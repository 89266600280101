type Values = {
  sourceName: string
  sourceEmail: string
  sourceWebsite: string
  sourceDescription: string
}

export const addSourceValidation = (values: Values) => {
  const errors: { [key: string]: string } = {}

  if (!values.sourceName) {
    errors.sourceName = ''
  }

  if (!values.sourceEmail) {
    errors.sourceEmail = ''
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.sourceEmail)
  ) {
    errors.sourceEmail = 'Invalid email'
  }

  if (!values.sourceWebsite) {
    errors.sourceWebsite = ''
  }

  if (!values.sourceDescription) {
    errors.sourceDescription = ''
  }

  return errors
}
