import styled, { css } from 'styled-components'

export const Options = styled.button<{ isOpen: boolean }>`
  display: flex;
  position: relative;
  border-radius: ${({ theme }) => theme.radius.small};
  max-width: 1.5rem;
  border: none;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.greyscale.white};
  outline: none;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.greyscale.lighter};
  }

  &:focus {
    outline: none;
  }

  ${p =>
    p.isOpen &&
    css`
      background-color: ${({ theme }) => theme.colors.greyscale.lighter};
    `}
`

export const Popover = styled.button`
  background-color: ${({ theme }) => theme.colors.greyscale.white};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.greyscale.light};
  max-width: 8rem;
  outline: none;

  padding: 0.5rem 0.35rem;

  position: absolute;
  top: 2rem;
  left: -2rem;
  z-index: 999;

  -webkit-box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
  box-shadow: 13px 10px 36px -5px rgba(0, 0, 0, 0.05);
`

export const PopoverItem = styled.span`
  display: block;
  padding: 0.5rem 0.35rem;
  border-radius: ${({ theme }) => theme.radius.small};

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyscale.lighter};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.greyscale.light};
  }
`
