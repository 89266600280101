import gql from 'graphql-tag'

export const GET_CATALOGUE_SIGNPOST = gql`
  query getCatalogue($searchTerm: String, $page: Int!, $pageSize: Int!) {
    results: signpostGoodsAll(
      searchTerm: $searchTerm
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      pageNumber
      pageSize
      totalEntries
      totalPages
      entries {
        id
        offers {
          id
          price
          source {
            id
            image {
              small
            }
            name
          }
        }
        name
        images {
          small
          large
        }
        description
        category
        categoryDescription
        price {
          avg
          min
          max
        }
        sku
        specifications {
          key
          value
        }
      }
    }
  }
`

export const DELETE_GOOD = gql`
  mutation deleteGood($id: ID!) {
    signpostGoodDelete(id: $id) {
      id
      name
    }
  }
`
