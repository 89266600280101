import styled from 'styled-components'
import { Link as StyledLink } from 'react-router-dom'

import * as UI from 'cimple-web-common'

export const Holder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
`

export const Link = styled(StyledLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${({ theme }) => theme.colors.brand.default};

  p {
    color: ${({ theme }) => theme.colors.brand.default};
    margin-left: 0.5rem;

    &:hover {
      color: ${({ theme }) => theme.colors.brand.dark};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.brand.dark};
  }
`

export const Arrow = styled(UI.Icons.Arrow)`
  transform: rotate(180deg);
`
