import React from 'react'
import Layout from '../components/Shared/Layout/Layout'
import Customers from '../components/Customers/Customers'

const BuyersView = () => {
  return (
    <Layout>
      <Customers />
    </Layout>
  )
}

export default BuyersView
